const Methods = [
    { "value": "visa", "label": "Visa" },
    { "value": "mastercard", "label": "Mastercard" },
    { "value": "american_express", "label": "American Express" },
    { "value": "discover", "label": "Discover" },
    { "value": "paypal", "label": "PayPal" },
    { "value": "apple_pay", "label": "Apple Pay" },
    { "value": "google_pay", "label": "Google Pay" },
    { "value": "samsung_pay", "label": "Samsung Pay" },
    { "value": "venmo", "label": "Venmo" },
    { "value": "sepa", "label": "SEPA" },
    { "value": "ach", "label": "ACH" },
    { "value": "swift", "label": "SWIFT" },
    { "value": "pix", "label": "PIX" },
    { "value": "ideal", "label": "iDEAL" },
    { "value": "giropay", "label": "GiroPay" },
    { "value": "blik", "label": "BLIK" },
    { "value": "twint", "label": "Twint" },
    { "value": "klarna", "label": "Klarna" },
    { "value": "afterpay", "label": "Afterpay" },
    { "value": "affirm", "label": "Affirm" },
    { "value": "boleto", "label": "Boleto Bancário" },
    { "value": "oxxo", "label": "OXXO" },
    { "value": "cash_on_delivery", "label": "Cash on Delivery" },
    { "value": "prepaid_visa", "label": "Prepaid Visa" },
    { "value": "prepaid_mastercard", "label": "Prepaid Mastercard" },
    { "value": "paysafecard", "label": "Paysafecard" },
    { "value": "bancontact", "label": "Bancontact" },
    { "value": "cartes_bancaires", "label": "Cartes Bancaires" },
    { "value": "interac", "label": "Interac" },
    { "value": "jcb", "label": "JCB" },
    { "value": "cheque", "label": "Cheque" },
    { "value": "direct_debit", "label": "Direct Debit" },
    { "value": "netbanking", "label": "NetBanking" },
    { "value": "upi", "label": "UPI" },
    { "value": "m_pesa", "label": "M-Pesa" },
    { "value": "airtel_money", "label": "Airtel Money" },
    { "value": "orange_money", "label": "Orange Money" },
    { "value": "tigo_cash", "label": "Tigo Cash" },
    { "value": "eco_cash", "label": "EcoCash" },
    { "value": "yup", "label": "YUP" },
    { "value": "pos", "label": "Point of Sale (POS)" },
    { "value": "mtn_mobile_money", "label": "MTN Mobile Money" },
    { "value": "wave", "label": "Wave" },
    { "value": "airtel_congo", "label": "Airtel Congo" },
    { "value": "bank_transfer", "label": "Bank Transfer" },
    { "value": "instant_transfer", "label": "Instant Transfer" },
    { "value": "euro_transfer", "label": "Euro Transfer" },
    { "value": "gbp_transfer", "label": "GBP Transfer" }
  ]
  
  export default Methods;