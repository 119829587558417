import React, { useState } from 'react';
import './global.css';  // Create this CSS file for custom styling

const PaymentSelect = ({ paymentMethod, setPaymentMethod }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const paymentMethods = [
    'Payments',
    'Bank Transfer',
    'PayPal',
    'Crypto Transfer',
    'Western Union',
    'Skrill',
    'Venmo',
    'Cash App'
  ];

  const filteredMethods = paymentMethods.filter(method =>
    method.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="P-conteanire">

    <div className="payment-select-container">

      <div className="payment-select" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
        <span>{paymentMethod}</span>
      </div>

      {isDropdownOpen && (
        <div className="payment-dropdown" >
          <input
            type="text"
            placeholder="Search payment method..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="payment-search"
          />
          <ul className="payment-list">
            {filteredMethods.map((method, index) => (
              <li
                key={index}
                onClick={() => {
                  setPaymentMethod(method);
                  setIsDropdownOpen(false);
                  setSearchTerm('');
                }}
              >
                {method}
              </li>
            ))}
          </ul>
        </div>
      )}

    </div>
    </div>

  );
};

export default PaymentSelect;
