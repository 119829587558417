import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import ChatWindow from './ChatWindow';
import ServiceBtn from './ServiceBtn';
import '../styles.css';
import './global.css';

const ChatPage = ({navigate}) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [typing, setTyping] = useState(false);
  const [element , setElement] = useState('white')
  const [SpaceName, setSpaceName] = useState('Dashboard')

console.log(element)
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    // Set initial state based on window size
    handleResize();

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Add event listener for clicks outside of sidebar
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listeners
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (


    <div className="dashboard">

      <Sidebar ref={sidebarRef} open={sidebarOpen} navigate={navigate} element={element} />
      <div className="content">
        <Topbar toggleSidebar={toggleSidebar} SpaceName={SpaceName}/>
        <div className="main-content chat-page">
          <ChatWindow  navigate={navigate} setTyping={setTyping} setElement={setElement} setSpaceName={setSpaceName}/> {/* Composant de fenêtre de chat */}
        </div>
      </div>
      <ServiceBtn navigate={navigate} typing={typing}/> {/* Ajouter le bouton flottant */}
    </div>
  );
};

export default ChatPage;
