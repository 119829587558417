import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars , faBell} from '@fortawesome/free-solid-svg-icons';
import '../styles.css';
import userP from '../userP.svg';

const Topbar = ({ toggleSidebar, SpaceName}) => {
  return (
    <div className="topbar">
      <FontAwesomeIcon style={{color: 'white'}} icon={faBars} className="menu-icon" onClick={toggleSidebar} />
      <h1 className="topbar-title">{SpaceName}</h1>
      <div className="topbar-actions">
        <div className="topbar-search">
          <input type="text" placeholder="Search..." />
        </div>
        <div className="topbar-notifications">  <FontAwesomeIcon style={{color: 'white'}} icon={faBell}  /></div>
        <img className="topbar-avatar" alt="profile" src={userP} style={{ width: '50px' }} />
      </div>
    </div>
  );
};

export default Topbar;
