import React, { useState, useRef, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import PaymentSelect from './PaymentSelect';
import '../styles.css';
import './global.css';
import { Web3Context } from '../Web3Context';

const P2PMarket = ({ navigate }) => {
  const { account, contractInstance, secondContractInstance, tokenInstance } = useContext(Web3Context);

  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth > 768);
  const [paymentMethod, setPaymentMethod] = useState('All Payments');
  const colors = ["#00aaff", "#2ee400", "#e4ba00", "#e4008d"];
  const [marketEle, setMarketEle] = useState('white');
  const [spaceName, setSpaceName] = useState('Dashboard');
  const [isSell, setIsSell] = useState(false);
  const [orderType, setOrderType] = useState('buy');
  const [actionName, setActionName] = useState('Sell');
  const [traders, setTraders] = useState([]);
  const [names, setNames] = useState([]);
  const [maxUSDT, setMaxusdt] = useState([]);
  const [minUSDT, setMinUsdt] = useState([]);
  const [rate, setRate] = useState([]);
  const [OrderData , setOrderData] = useState ([{}])
  const [addresses , setAddress] = useState('')
  console.log('Order Data',OrderData )

  
  const sidebarRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => setSidebarOpen(window.innerWidth > 768);
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

  useEffect(() => {
    setActionName(orderType === 'sell' ? 'Sell' : 'Buy');
    setIsSell(orderType === 'sell');
  }, [orderType]);

  useEffect(() => {
    if (location.pathname === '/P2PMarket') {
      setMarketEle('aqua');
      setSpaceName('Place Order');
    } else {
      setMarketEle('white');
      setSpaceName('Dashboard');
    }
  }, [location]);

  useEffect(() => {
    const getUsersOrdersOfSell = async () => {
      const numberOfOrders = await contractInstance.methods.numberOfOrders().call();
      const orders = [];
      const addresses = [];
      const maxAmounts = [];
      const minAmounts = [];
      const rates = [];
      const names = [];

      for (let i = 1; i <= numberOfOrders; i++) {
        const order = await contractInstance.methods.userCurrentOrders(i).call()
        console.log('order' , order)
        if (order[0] !== '0x0000000000000000000000000000000000000000') {
          addresses.push(order[0]);
          maxAmounts.push(Number(order[4]) / 1e18);
          minAmounts.push(Number(order[3]) / 1e18);
          const user = await secondContractInstance.methods.Users(order[0]).call();
          names.push(user[5]);
          rates.push(Number(order[6]));
          orders.push(order);

          setNames([...names]);
          setTraders([...orders]);
          setMaxusdt([...maxAmounts]);
          setMinUsdt([...minAmounts]);
          setRate([...rates]);
          setAddress([...addresses])


          setOrderData ([{
            busName : [...names],
            Mxamount : [...maxAmounts],
            Mnamount : [...minAmounts],
            Rate : [...rates] ,
            Addresses : addresses,
  
          }])
        }

     


      }

     
    };
    const getUsersOrdersOfBuy = async () => {
      const numberOfOrders = await contractInstance.methods.numberOfOrders().call();
      const orders = [];
      const addresses = [];
      const maxAmounts = [];
      const minAmounts = [];
      const rates = [];
      const names = [];

      for (let i = 1; i <= numberOfOrders; i++) {
        const order = await contractInstance.methods.userCurrentOrderForBuys(i).call()
        console.log('order' , order)
        if (order[0] !== '0x0000000000000000000000000000000000000000') {
          addresses.push(order[0]);
          maxAmounts.push(Number(order[4]) / 1e18);
          minAmounts.push(Number(order[3]) / 1e18);
          const user = await secondContractInstance.methods.Users(order[0]).call();
          names.push(user[5]);
          rates.push(Number(order[6]));
          orders.push(order);

          setNames([...names]);
          setTraders([...orders]);
          setMaxusdt([...maxAmounts]);
          setMinUsdt([...minAmounts]);
          setRate([...rates]);
          setAddress([...addresses])


          setOrderData ([{
            busName : [...names],
            Mxamount : [...maxAmounts],
            Mnamount : [...minAmounts],
            Rate : [...rates] ,
            Addresses : addresses,
  
          }])
        }
      }

     
    };

    if (orderType === 'sell') {
      getUsersOrdersOfBuy();

    } else {
      getUsersOrdersOfSell();

    }

  }, [contractInstance, secondContractInstance, orderType]);

  const checkChecked = () => {
    orderType === 'sell' ? navigate('/SellUsdt') : navigate('/BuyUsdt');
  };

  const assignColorsToTraders = (traders, colors) => {
    return traders.map((trader, index) => ({
      ...trader,
      color: colors[index % colors.length],
    }));
  };

  const tradersWithColors = assignColorsToTraders(traders, colors);

  return (
    <>

    <div className="p2p-market-page">
      <Sidebar ref={sidebarRef} open={sidebarOpen} navigate={navigate} marketEle={marketEle} />
      <div className="content">
        <Topbar toggleSidebar={toggleSidebar} navigate={navigate} spaceName={spaceName} />
        <div className="p2p-market">
          <div className="header">
            <div className="header-left">
              <div className="toggle-buttons">
                <input
                  type="radio"
                  id="buy"
                  name="orderType"
                  value="buy"
                  checked={orderType === 'buy'}
                  onChange={(e) => setOrderType(e.target.value)}
                  className='inpM'
                />
                <label                    style={{padding:'11px', marginLeft:'-10px'}}
 className='inpML'
 htmlFor="buy">Buy</label>
                <input
               className='inpM'

                  type="radio"
                  id="sell"
                  name="orderType"
                  value="sell"
                  checked={orderType === 'sell'}
                  onChange={(e) => setOrderType(e.target.value)}
                />
                <label style={{padding:'11px', marginRight:'-10px'}} className='inpML' htmlFor="sell">Sell</label>
              </div>
            </div>
            <div className="header-right">
              <PaymentSelect paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod} />
            </div>
          </div>
          <div className="traders">
            {tradersWithColors.map((trader, index) => (
              <div className="trader-card" key={index}>
                {names[index] !== '' && (
                  <div className="trader-info">
                    <h3 style={{ borderLeft: `5px solid ${trader.color}` }}>{names[index]} <span className="verified"></span></h3>
                    <p className="Rate">Rate: <span className="infos big">${rate[index]} / USD</span></p>
                    <p className="pForOrder">Quantity: <span className="infos small">{maxUSDT[index]} USDT</span></p>
                    <p className="pForOrder">Min: <span className="infos small">{minUSDT[index]} USDT</span></p>
                    <p className="pForOrder">Payment: <span className="infos small">{trader.payment}</span></p>
                  </div>
                )}
                <button className={`restricted-btn ${isSell ? "sell" : "buy"}`} onClick={checkChecked}>{actionName}</button>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
   

        </>

  );
};

export default P2PMarket;
