import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import ServiceChatWin from './ServiceChatWin';
import '../styles.css';
import './global.css';

const ChatPage = () => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const sidebarRef = useRef(null);
  const [element , setElement] = useState('white')
  const [SpaceName, setSpaceName] = useState('Dashboard')
  
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    // Set initial state based on window size
    handleResize();

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Add event listener for clicks outside of sidebar
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listeners
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="dashboard">
      <Sidebar ref={sidebarRef} open={sidebarOpen} navigate={navigate} element={element} />
      <div className="content">
       <Topbar toggleSidebar={toggleSidebar} SpaceName={SpaceName}/>
         <div className="main-content chat-page">
          <ServiceChatWin  navigate={navigate} setSpaceName={setSpaceName} setElement={setElement} /> {/* Composant de fenêtre de chat */}
        </div>
      </div>
    </div>
  );
};

export default ChatPage;
