import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faDatabase } from '@fortawesome/free-solid-svg-icons';
import '../styles.css';
import { Web3Context } from '../Web3Context';

const Activities = ({canReloadAct}) => {
  const { account, contractInstance } = useContext(Web3Context);
  const [amounts, setAmounts] = useState([]);
  const [actionNames, setActionNames] = useState([]);
  const [isActive, setIsActive] = useState(false);
  console.log("render ACts");
 
  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const isActive = await contractInstance.methods.isActive(account).call();
        setIsActive(isActive);
        console.log('isActive', isActive);

        if (isActive) {
          const activitiesArray = await contractInstance.methods.getAllActivities(account).call();
          const value = activitiesArray.amounts.length;
          const amountsTemp = [];
          const namesTemp = [];

          for (let i = value - 1; i >= Math.max(0, value - 4); i--) {
            amountsTemp.push(((Number(activitiesArray.amounts[i])) / 1e18).toFixed(2));
            namesTemp.push(activitiesArray._name[i]);
          }

          console.log('amounts', amountsTemp);
          setAmounts(amountsTemp);
          setActionNames(namesTemp);
        }
      } catch (error) {
        console.error("Error fetching activities:", error);
      }
    };

    if (account && contractInstance) {
      fetchActivities();
    }

    if(canReloadAct) {
      fetchActivities();

    }
  }, [account, contractInstance]);

  return (
    <div className="card statistics-card">
      <h2 style={{ marginBottom: '5px', fontSize: '1em' }}>Activities</h2>
      {isActive ? (
        <div className="activities">
          {amounts.map((amount, index) => (
            <div key={index} className="activity-item">
              <FontAwesomeIcon
                icon={actionNames[index] === 'deposit' ? faArrowDown : faArrowUp}
                className="activity-icon"
              />
              <div className="activity-details">
                <span className="activity-type" style={{ marginLeft: '10px' }}>{actionNames[index]}</span>
                <span className="activity-amount" style={{ marginLeft: '10px' }}>{amount}</span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="dataACt">
          <FontAwesomeIcon style={{ color: 'white', width: '60px' }} icon={faDatabase} /> No Data available
        </div>
      )}
    </div>
  );
};

export default Activities;
