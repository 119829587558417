import React, { useState } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faPaperclip  , faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import '../styles.css';

const ChatWindow = ({setTyping, setElement, setSpaceName}) => {
  const location = useLocation();

  const [messages, setMessages] = useState([
    { type: 'received', name: 'John', text: 'Hi, I am happy to trade with you.', time: '10:00 AM' },
    { type: 'sent', name: 'You', text: 'Can you tell me more about your payment options?', time: '10:02 AM' }
  ]);

  const [inputValue, setInputValue] = useState('Hi, I am happy to trade with you.😊');
  const [disableState, setDisableState] = useState(false);

  useEffect(() => {
  if (location.pathname === '/ChatPage') {
    setElement('aqua')
    setSpaceName('Live Chat')
  } else {
    setElement('white')
  }
  }, [location,setElement,setSpaceName]);


  const handleSendMessage = () => {
    if (inputValue.trim()) {
      setMessages([...messages, { type: 'sent', name: 'You', text: inputValue, time: new Date().toLocaleTimeString() }]);
      setInputValue('');
      setDisableState(true);
      setTyping(false)

    }
  };

  const handleSelectMessage = (message) => {
    setInputValue(message);
    setDisableState(false);
    setTyping(true)
  };

  const handleSendImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setMessages([...messages, { type: 'sent', name: 'You', image: e.target.result, time: new Date().toLocaleTimeString() }]);
      };
      reader.readAsDataURL(file);
    }
  };
  const [showWarning, setShowWarning] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowWarning(false);
    }, 5000); // 5 seconds

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, []);
   
  const predefinedMessages = [
    'Hi, I am happy to trade with you.',
    'Can you tell me more about your payment options?',
    'I am interested in buying USDT.',
    'What is your preferred payment method?',
    'Let\'s discuss the transaction details.'
  ];

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setDisableState(value.trim().length === 0);
    setTyping(value.trim().length > 0)
  };
  
  return (
    <div className="chat-window">
     {showWarning && (
        <div className="chat-warner" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <span style={{ color: 'white', fontWeight: 'bold', marginRight: '15px' }}>Important notice</span>: Do not release Crypto before receiving the payment!
          <FontAwesomeIcon style={{ marginLeft: '5px' }} icon={faExclamationCircle} />
        </div>
      )}
      <div className="chat-header">
        <h2 className='CHTWT'>Chat with Trader</h2>
      </div>
 
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.type}`}>


        
              <p className="message-name" style={{ marginTop: 0, marginBottom: '3px', fontWeight: 'bold', color: 'white' }}>
                {msg.name}
              </p>

            {msg.text && <p className='msgText' style={{  marginBottom: 0 }}>{msg.text}</p>}
            {msg.image && <img src={msg.image} alt="sent" style={{ maxWidth: '100%', marginTop: '10px', borderRadius: '10px' }} />}
            <span className="message-time">{msg.time}</span>
          </div>
        ))}
      </div>
      <div className="chat-input">
        <input
          type="text"
          placeholder="Type your message here..."
          value={inputValue}
          onChange={handleInputChange}
        />

<div className="Btn Msg-Send">

        <button type="button" disabled={disableState} onClick={handleSendMessage}> 
        <FontAwesomeIcon icon={faPaperPlane} />

        
        </button>
        </div>

        <label className="file-input-label">
          <FontAwesomeIcon icon={faPaperclip} />
          <input type="file" accept="image/*" onChange={handleSendImage} style={{ display: 'none' }} />
        </label>
        <div className="predefined-messages">
        
          <ul className="predefined-list">
            {predefinedMessages.map((msg, index) => (
              <li key={index} onClick={() => handleSelectMessage(msg)}>
                {msg}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ChatWindow;
