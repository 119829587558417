// src/components/MyOrdersPage.js
import React, { useState, useRef, useEffect, useContext, useCallback } from 'react';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import OrderItem from './OrderItem';
import AddOrderItem from './AddOrderItem';
import { Web3Context } from '../Web3Context';

const MyOrdersPage = ({ navigate }) => {
  const { account, contractInstance } = useContext(Web3Context);
  const [buyOrders, setBuyOrders] = useState([]);
  const [type, setType] = useState('buy');
  const [status, setStatus] = useState('completed');
  const [orderID, addOrderID] = useState([]);
  const [paymentInfos, setPaymentInfos] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const sidebarRef = useRef(null);

  const fetchOrders = useCallback(async () => {
    console.log('Fetching orders...');

    try {
      const numberOfOrders = Number(await contractInstance.methods.NumberOfSellOrdersByUser(account).call());
      console.log('Account:', account);
      console.log('Number of Orders:', numberOfOrders);

      const buyOrdersArray = [];
      const orderIDArray = [];


      for (let i = 0; i < numberOfOrders; i++) {
        const query = await contractInstance.methods.OrdersAvailableOfSeller(account, i).call();
        console.log("Response ", query);

        if (Number(query.totalAmount[i] )=== 0) break;

        buyOrdersArray.push(query);
        orderIDArray.push(Number(query.orderID));
        console.log('orderIDArray' , orderIDArray)
      }

      setBuyOrders(buyOrdersArray);
      addOrderID(orderIDArray);
      console.log('orderID:', orderID);

      console.log('Buy Orders:', buyOrdersArray);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  }, [account, contractInstance]);


 


  const fetchBuyOrders = useCallback(async () => {
    console.log('Fetching orders...');

    try {
      const numberOfBuyOrders = Number(await contractInstance.methods.NumberOfBuyOrdersByUser(account).call());
      console.log('Account:', account);
      console.log('Number of Orders:', numberOfBuyOrders);

      const buyOrdersArray = [];
      const orderIDArray = [];


      for (let i = 0; i < numberOfBuyOrders; i++) {
        const query = await contractInstance.methods.OrdersAvailableOfBuyer(account, i).call();
        console.log("Response ", query);

        if (Number(query.totalAmount[i] )=== 0) break;

        buyOrdersArray.push(query);
        orderIDArray.push(Number(query.orderID));
        console.log(orderIDArray)
      }

      setBuyOrders(buyOrdersArray);
      addOrderID((orderIDArray));
      console.log('orderID:',orderID); 

      console.log('Buy Orders:', buyOrdersArray);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  }, [account, contractInstance]);



  

  useEffect(() => {
    const updateTypeAndStatus = () => {
      if (type === 'buy') {
        setStatus('Live');
      } else if (type === 'sell') {
        setStatus('Live');
      } else if (type === 'completed') {
        setStatus('All');
      } else if (type === 'failed') {
        setStatus('All');
      }
    };

    updateTypeAndStatus();

    if (type === 'sell') { 
      fetchOrders();

    }else {
      fetchBuyOrders()
    }

  }, [type, fetchOrders]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="my-orders-page">
      <Sidebar ref={sidebarRef} open={sidebarOpen} OrdersEle={'aqua'} navigate={navigate} />
      <div className="main-content">
        <Topbar toggleSidebar={toggleSidebar} SpaceName="My Orders" />
        <div className="content">
          <div className="O">
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent', backdropFilter: 'blur(50px)' }} className="opt">
              <p className='OrderOpt' style={type === 'buy' ? { borderBottom: '1px solid #00cb2c' } : { borderBottom: 'none' }} onClick={() => setType('buy')}>Buy</p>
              <p className='OrderOpt' style={type === 'sell' ? { borderBottom: '1px solid #00cb2c' } : { borderBottom: 'none' }} onClick={() => setType('sell')}>Sell</p>
              <p className='OrderOpt' style={type === 'completed' ? { borderBottom: '1px solid #00cb2c' } : { borderBottom: 'none' }} onClick={() => setType('completed')}>Completed</p>
              <p className='OrderOpt' style={type === 'failed' ? { borderBottom: '1px solid #00cb2c' } : { borderBottom: 'none' }} onClick={() => setType('failed')}>Failed</p>
            </div>
          </div>

          <div className="contenaire-O"></div>

          <div className="orders-list">
            {type === 'completed' || type === 'failed' ? '' : <AddOrderItem navigate={navigate}/>}
            {buyOrders.map((order, index) => (
              <OrderItem
                key={index}
                isOrderAvailableYet={order.isOrderAvailableYet}
                rate={order.rate}
                devise={order.devise}
                timestamp={order.timestamp}
                totalAmount={order.totalAmount}
                type={type}
                status={status}
                paymentMethods={paymentInfos[index]}
                
              />
            ))}
          </div>
        </div>
      </div>
      <div style={{ top: '80%', padding: '200px', left: '80%', backdropFilter: 'blur(130px)' }} className="flou"></div>
    </div>
  );
};

export default MyOrdersPage;
