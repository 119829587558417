// src/Web3Context.js
import React, { createContext, useState, useEffect } from 'react';
import web3 from './Web3.js/web3';
import contract from './Web3.js/contract';
import secondContract from './Web3.js/secondContract';
import token from './Web3.js/token';

export const Web3Context = createContext();

export const Web3Provider = ({ children }) => {
  const [account, setAccount] = useState('');
  const [contractInstance, setContractInstance] = useState(null);
  const [secondContractInstance, setSecondContractInstance] = useState(null);
  const [tokenInstance, setTokenInstance] = useState(null);

  useEffect(() => {
    const loadBlockchainData = async () => {
      const accounts = await web3.eth.getAccounts();
      setAccount(accounts[0]);
      setContractInstance(contract);
      setSecondContractInstance(secondContract);
      setTokenInstance(token);
    };
    loadBlockchainData();
  }, []);

  return (
    <Web3Context.Provider value={{ account, contractInstance, secondContractInstance , tokenInstance}}>
      {children}
    </Web3Context.Provider>
  );
};
