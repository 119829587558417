// src/components/OrderPage.js
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import Sidebar from './Sidebar';
import Topbar from './Topbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentDots } from '@fortawesome/free-solid-svg-icons';
import './global.css';
import '../styles.css'

const OrderPage = ({ navigate }) => {
    const location = useLocation();

    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [element , setElement] = useState('white')
    const [SpaceName, setSpaceName] = useState('Dashboard')
    const [isBuy,setIsBuy] = useState(false);
    const [methods, setMethods] = useState('')
    const [whoIsIt, setwhoIsIt] = useState('')

    useEffect(() => {
        if (location.pathname === '/OrderPage') {
          setElement('aqua')
          setSpaceName('Order Page')
        } else {
          setElement('white')
        }
        }, [location,setElement,setSpaceName]);
      
       useEffect(() => {
      if (isBuy) {
        setIsBuy(true)
        setMethods('Bank Payement')
        setwhoIsIt('Seller')

      } else {
        setIsBuy(false)
        setMethods('Paradiz Payment')
        setwhoIsIt('Buyer')

      }
        }, [setwhoIsIt,isBuy, setIsBuy]);

    const sidebarRef = useRef(null);

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 768) {
          setSidebarOpen(false);
        } else {
          setSidebarOpen(true);
        }
      };
  
      // Set initial state based on window size
      handleResize();
  
      // Add event listener for resize
      window.addEventListener('resize', handleResize);
  
      // Add event listener for clicks outside of sidebar
      const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
          setSidebarOpen(false);
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
  
      // Clean up event listeners
      return () => {
        window.removeEventListener('resize', handleResize);
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
    const toggleSidebar = () => {
      setSidebarOpen(!sidebarOpen);
    };
  return (
    <div className="order-page">
            <div className="content">

      <Sidebar ref={sidebarRef} open={sidebarOpen} navigate={navigate} element={element}  />
      <div className="main-content">
        <Topbar toggleSidebar={toggleSidebar} navigate={navigate} SpaceName={SpaceName} />
        <div className="order-details">
          <div className="order-header">
            <h2>Order Created</h2>
            <button className="cancel-order">Cancel the Order</button>
          </div>
          <div className="order-info">
            <div className="order-item">
              <span>Buy USDT</span>
              <div className="order-values">
                <p>Fiat Amount: $700.00</p>
                <p>Price: $1,000</p>
                <p>Receive Quantity: 700.00 USDT</p>
              </div>
            </div>
            <div className="payment-method">
              <h3>Payment Method</h3>
              <p>{methods}</p>

            </div>

           {isBuy && <PaymentMethods />} 

            <div className="advertiser-terms">
              <h3>Advertiser's Terms</h3>
              <p>Only use your own payment account to transfer funds to the seller. Third-party payments are prohibited.</p>
            </div>
          </div>
          <button className="view-payment-details">Confirm Payment</button>
          <button className="message-seller" onClick={()=> navigate('/ChatPage')} >
            <FontAwesomeIcon icon={faCommentDots} /> Message {whoIsIt}
          </button>
        </div>
      </div>
      </div>

    </div>

    
  );
};

const PaymentMethods = () => {
    return (  
         <div className="payment-details">
    <p ><strong className='pInfos'>Account Name:</strong> John Doe</p>
    <p ><strong className='pInfos'>Bank Name:</strong> Cambodia National Bank</p>
    <p ><strong className='pInfos'>Account Number:</strong> 123456789</p>
    <p ><strong className='pInfos'>SWIFT Code:</strong> CNBKHPP</p>
  </div>
  );
};

export default OrderPage;
