
const Header = ({ navigate }) => {
    return (
      <header className="App-header">
        <div className="header-container">
          <nav>
            <ul className="nav-links">
              <li><a href="#login" onClick={() => navigate('/login')}>Login</a></li>
              <li><a  href="#register" onClick={() => navigate('/Registration')}>Register</a></li>
              <li><a href="#home" onClick={() => navigate('/home')}>Home</a></li>
            </ul>
          </nav>
        </div>
      </header>
    );
  };
export default Header;