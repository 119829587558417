import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import BalanceCard from './BalanceCard';
import RecentActivities from './RecentActivities';
import TransactionsTable from './TransactionsTable';
import OrderStatus from './OrderStatut';
import BitcoinPrice from './BitcoinPrice';
import Activities from './Activities';
import ServiceBtn from './ServiceBtn';
import '../styles.css';
import './global.css';

const Dashboard = ({ navigate }) => {
  console.log("render Dashboard");

  const [name, setName] = useState('Para People');
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [DashEle, setDashEle] = useState('white');
  const [SpaceName, setSpaceName] = useState('Dashboard');
  const [canReloadAct,setCanRealodAct] = useState(false)
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setSidebarOpen(window.innerWidth > 768);
    };

    // Set initial state based on window size
    handleResize();

    // Add event listener for resize
    window.addEventListener('resize', handleResize);

    // Add event listener for clicks outside of sidebar
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Clean up event listeners
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    if (location.pathname === '/Dashboard') {
      setDashEle('aqua');
      setSpaceName('Dashboard');
    } else {
      setDashEle('white');
    }
  }, [location.pathname]);

  return (
    <div className="dashboard">
      <Sidebar ref={sidebarRef} open={sidebarOpen} navigate={navigate} DashEle={DashEle} />
      <div className="content">
        <Topbar toggleSidebar={toggleSidebar} SpaceName={SpaceName} />
        <div className="main-content">
          <h1 className="dashboard-title" style={{ marginTop: '-10px', marginLeft: '10%' }}>
            <span style={{ fontWeight: 100, fontSize: '0.42em' }}>Welcome back,</span>
            <br />
            <span style={{ color: '#66ccff', fontWeight: 'bold', fontSize: '0.75em' }}>{name}</span>
          </h1>
          <div className="grid">
            <div className="grid-item">
              <BalanceCard setCanRealodAct={setCanRealodAct} setName={setName} />
            </div>
            <div className="grid-item">
              <OrderStatus navigate={navigate} />
            </div>
            <div className="grid-item">
              <Activities canReloadAct={canReloadAct} navigate={navigate} />
            </div>
            <div className="grid-item">
              <BitcoinPrice />
            </div>
            <div className="grid-item">
              <TransactionsTable />
            </div>
            <div className="grid-item">
              <RecentActivities />
            </div>
          </div>
        </div>
      </div>
      <div className='blrs' style={{ top: '5%', right: '0', backgroundColor: 'rgb(0, 70, 244)', padding: '50px', position: 'fixed', zIndex: '-1', borderRadius: '50%', filter: 'blur(150px)' }}></div>
      <div className="blur-background" style={{ backgroundColor: 'rgb(0, 89, 255)', padding: '110px', borderRadius: '50%', color: '#ffffff', right: '80%', display: 'flex', position: 'fixed', alignItems: 'center', justifyContent: 'center', fontSize: '1.5rem', bottom: '0%', zIndex: '-1000', filter: 'blur(160px)' }}></div>
      <ServiceBtn navigate={navigate} /> {/* Ajouter le bouton flottant ici */}
    </div>
  );
};

export default Dashboard;
