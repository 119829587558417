import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import 'chart.js/auto';
import bitcoinLogo from '../bitcoin.svg'; // Assurez-vous que le chemin vers l'image est correct

const BitcoinPrice = () => {
  console.log("render BitcoinPrice");

  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Bitcoin Price',
        data: [],
        fill: false,
        backgroundColor: '#FF9900',
        borderColor: '#FF9900',
      },
    ],
  });

  useEffect(() => {
    const fetchBitcoinPrice = async () => {
      try {
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/coins/bitcoin/market_chart',
          {
            params: {
              vs_currency: 'usd',
              days: 7, // Derniers 7 jours
              interval: 'daily',
            },
          }
        );

        const prices = response.data.prices;
        const labels = prices.map((price) => {
          const date = new Date(price[0]);
          return `${date.getDate()}/${date.getMonth() + 1}`;
        });

        const dataPoints = prices.map((price) => price[1]);

        setData({
          labels: labels,
          datasets: [
            {
              label: 'Bitcoin Price',
              data: dataPoints,
              fill: false,
              backgroundColor: '#FF9900',
              borderColor: '#FF9900',
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching Bitcoin price:', error);
      }
    };

    fetchBitcoinPrice();
  }, []);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          color: '#ffffff',
        },
      },
      y: {
        ticks: {
          color: '#ffffff',
        },
      },
    },
  };

  return (
    <div className="card bitcoin-price-card">
      <div className="bitcoin-header">
        <img src={bitcoinLogo} alt="Bitcoin Logo" className="bitcoin-logo" />
        <h2 className="bitcoin-title">Bitcoin Price</h2>
      </div>
      <div className="bitcoin-chart">
        <Line data={data} options={options} />
      </div>
      <style jsx>{`
        .bitcoin-price-card {
          color: #ffffff;
          padding: 20px;
          border-radius: 30px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
        }

        .bitcoin-header {
          display: flex;
          align-items: center;
          top: 10px;
          position: fixed;
        }

        .bitcoin-logo {
          width: 30px;
          height: auto;
          margin-right: 10px;
        }

        .bitcoin-title {
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          margin: 0;
          font-size: 15px;
        }

        .bitcoin-chart {
          margin-top: 20px;
          position: relative;
          width: 100%;
          height: 300px; /* Hauteur fixe pour le conteneur du graphique */
        }
      `}</style>
    </div>
  );
};

export default BitcoinPrice;
