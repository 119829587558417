// src/App.js
import React from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import AppHtml from './home';
import Registration from './Registration';
import Login from './login';
import Dashboard from './components/Dashboard';
import ChatPage from './components/ChatPage';
import ServiceChat from './components/ServiceChat';
import P2PMarket from './components/Market';
import SellUsdt from './components/SellUsdt';
import BuyUsdt from './components/BuyUsdt';
import OrderPage from './components/OrderPage';
import MyOrdersPage from './components/Orders';
import ProfileSettings from './components/Profile';
import Notification from './notificationTools/notfication';
import Register from './Registration';
import AddOrder from './components/AddOrder';

import { Web3Provider } from './Web3Context';

function App() {
  return (
    <Web3Provider>
      <Routes>
        <Route path="/register" element={<RegisterWrapper />} />
        <Route path="/login" element={<LoginWrapper />} />
        <Route path="/" element={<HomeWrapper />} />
        <Route path="/dashboard" element={<DashboardWrapper />} />
        <Route path="/ChatPage" element={<ChatPageWrapper />} />
        <Route path="/ServiceChat" element={<ServiceChatWrapper />} />
        <Route path="/P2PMarket" element={<P2PMarketWrapper />} />
        <Route path="/SellUsdt" element={<SellUsdtWrapper />} />
        <Route path="/BuyUsdt" element={<BuyUsdtWrapper />} />
        <Route path="/OrderPage" element={<OrderPageWrapper />} />
        <Route path="/Orders" element={<MyOrdersPageWrapper />} />
        <Route path="/Profile" element={<ProfileSettingsWrapper />} />
        <Route path="/Noti" element={<NotiWrapper />} />
        <Route path="/Registration" element={<RegWrapper />} />
        <Route path="/AddOrder" element={<AddOrderWrapper />} />







        {/* Redirection par défaut */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Web3Provider>
  );
}

function RegisterWrapper() {
  const navigate = useNavigate();
  return <Registration navigate={navigate} />;
}

function LoginWrapper() {
  const navigate = useNavigate();
  return <Login navigate={navigate} />;
}

function HomeWrapper() {
  const navigate = useNavigate();
  return <AppHtml navigate={navigate} />;
}

function DashboardWrapper() {
  const navigate = useNavigate();
  return <Dashboard navigate={navigate} />;
}

function ChatPageWrapper() {
  const navigate = useNavigate();
  return <ChatPage navigate={navigate} />;
}

function ServiceChatWrapper() {
  const navigate = useNavigate();
  return <ServiceChat navigate={navigate} />;
}

function P2PMarketWrapper() {
  const navigate = useNavigate();
  return <P2PMarket navigate={navigate} />;
}

function SellUsdtWrapper() {
  const navigate = useNavigate();
  return <SellUsdt navigate={navigate} />;
}

function BuyUsdtWrapper() {
  const navigate = useNavigate();
  return <BuyUsdt navigate={navigate} />;
}

function OrderPageWrapper() {
  const navigate = useNavigate();
  return <OrderPage navigate={navigate} />;
}
function MyOrdersPageWrapper() {
  const navigate = useNavigate();
  return <MyOrdersPage navigate={navigate} />;
}
function ProfileSettingsWrapper() {
  const navigate = useNavigate();
  return <ProfileSettings navigate={navigate} />;
}
function NotiWrapper() {
  const navigate = useNavigate();
  return <Notification navigate={navigate} />;
}

function RegWrapper() {
  const navigate = useNavigate();
  return <Register navigate={navigate} />;
}

function AddOrderWrapper() {
  const navigate = useNavigate();
  return <AddOrder navigate={navigate} />;
}


export default App;
