import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './Noti.css'



function Notification () {

    return (
        <div className="noti">
  <div className="noti-cont">

  <div className="noti-title"> Connected To Web3  <FontAwesomeIcon icon={faCheckCircle}> </FontAwesomeIcon>
 </div>
 <div className="noti-contente">
 <p>

 You are currently connected, you can get started by clicking on login

</p>
 </div>
 
 </div>

        </div>
    )
}



export default Notification;