import React, { useEffect, useRef, useState } from 'react';
import '../styles.css';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Web3Context } from '../Web3Context';


import { faArrowDown, faArrowUp, faBitcoinSign , faCheckCircle, faExclamationCircle} from '@fortawesome/free-solid-svg-icons';

const BalanceCard = ({setName, setCanRealodAct}) => {

  console.log("render");

  const { account, contractInstance, secondContractInstance , tokenInstance} = useContext(Web3Context);
  console.log('token contract: ', tokenInstance.options.address)
 const [balance , setBalance] = useState(0)
 console.log(balance)
 const [availableBalance , setAvailableBalance] = useState(0)
 const [CanDisplay, setCandisplay] = useState(false);
 const [CanDisplayW, setCandisplayW] = useState(false);

 const [displayBalance, setDisplayBalance] = useState(0);


 console.log('CanDisplay', CanDisplay)


 /*
 useEFFECT HERE
  */


 useEffect(() => {
  const startDisplaying = (blockedAmount) => {
    let start = 0;
    const end = parseFloat(blockedAmount.toFixed(2));
    const duration = 2000; // durée de l'animation en millisecondes
    const increment = end / (duration / 16); // augmentation par frame (environ 60fps)
  
    const updateCounter = () => {
      start += increment;
      if (start >= end) {
        setDisplayBalance(end.toFixed(2));
        clearInterval(intervalId);
      } else {
        setDisplayBalance(start.toFixed(2));
      }
    };
  
    const intervalId = setInterval(updateCounter, 16); // mettre à jour toutes les 16ms (~60fps)
  
    return () => clearInterval(intervalId); // nettoyer l'intervalle lors du démontage du composant
  };
  
  const changeName = async () => {

    const getName = await secondContractInstance.methods.name(account).call();    setName((getName).split(' ')[0]);

    console.log(getName);
    console.log(secondContractInstance, contractInstance, 'et account :', account);
  };

  const getBalance = async () => {
    const balances = Number(await contractInstance.methods.userBalance(account).call());
    const blockedAmount = Number(await contractInstance.methods.TotalAmountBlocked(account).call());
    const userBalance = balances + blockedAmount;

    setBalance((userBalance / 1e18).toFixed(2));
    setAvailableBalance((balances / 1e18).toFixed(2));
    console.log(balances);
    console.log(userBalance);
    startDisplaying(userBalance / 1e18); // Passez le solde total à afficher
  };

  getBalance();
  changeName();
}, [account, contractInstance, secondContractInstance, setName ]);

 
  return (
    <>
      <div className="balance-stats">

    <div  style={{ justifyContent: 'center', alignItems:'center'}} className="balance-card" >
    <div className="balance-box">

      <div className="balance-header">
      <div className="title-of-balance" style={{}}>
      <h2 className="balanceTitle" style={{fontWeight:100, fontSize:'0.8em'}}>Total Balance</h2>

        </div>

      </div>
      <div className="balanceS" style={{}}>

      <p className="balance-amount" style={{color: 'white'}} >{displayBalance} USD</p>
      </div>

        <div className="available-balance">
          Available Balance: <span className="available-amount">{availableBalance} USD</span>
        </div>
        </div >

        <div className="balance-buttons">
        <button className="balance-button" id="Deposit" onClick={()=>  setCandisplay(!CanDisplay)}>      
              <FontAwesomeIcon icon={faArrowDown} /> Deposit
          </button>
        
        <button className="balance-button"  id="Withdraw"  onClick={()=>  setCandisplayW(!CanDisplayW)}>
        <FontAwesomeIcon icon={faArrowUp}  />  Withdraw</button>
      </div>
      </div>
      
    </div>
    <DisplayInput setCanRealodAct={setCanRealodAct} setDisplayBalance={setDisplayBalance}  setAvailableBalance={setAvailableBalance} CanDisplay={CanDisplay} />
    <DisplayInputWithdraw setCanRealodAct={setCanRealodAct} setDisplayBalance={setDisplayBalance}  setAvailableBalance={setAvailableBalance} CanDisplayW={CanDisplayW} />

        </>

  );
};


const DisplayInput = ({CanDisplay,setDisplayBalance, setAvailableBalance, setCanRealodAct}) => {
  const { account, contractInstance, tokenInstance } = useContext(Web3Context);
  const [currentText, setText] = useState('Deposit');
  const [currentInconName, setInconeName] = useState(faBitcoinSign);
  const [currentColor, setCurrentColor] = useState('darkorange');
  const [value , setvalue] = useState(0);

  const amountRef = useRef();
  const depositRef = useRef();
  const notiRef = useRef();
  const closeRef = useRef();
  const MaxRef = useRef();

  const getbalance = async()=> {
    const balances =  Number(await contractInstance.methods.userBalance(account).call());
    const blockedAmount = Number(await contractInstance.methods.TotalAmountBlocked(account).call());
    const userBalance = (balances) + (blockedAmount)

      setDisplayBalance((userBalance/1e18).toFixed(2))
      setAvailableBalance((balances/1e18).toFixed(2));

      console.log(balances)
      console.log(userBalance)

  }
  const handleDeposit = async (e) => {
    e.preventDefault();
    try {
      const amount = Number(amountRef.current.value) * 1e18;
      console.log("Amount: ", amount);

      const allowance = Number(await tokenInstance.methods.allowance(account, contractInstance.options.address).call());
      console.log("Allowance: ", allowance);

      if (allowance >= amount) {
        const makeDeposit = await contractInstance.methods.deposit(amount).send({ from: account });
        if (makeDeposit) {
          console.log('Deposit successful');
          setText('Successfully completed');
          setInconeName(faCheckCircle);
          setCurrentColor('#00cb2c');
          depositRef.current.style.display = 'none';
          closeRef.current.display = 'none'
          amountRef.current.display = 'none'
          setCanRealodAct(true)

          setTimeout(() => {
            depositRef.current.style.display = 'inline';
            closeRef.current.style.display = 'inline'
            amountRef.current.style.display = 'inline'
            amountRef.current.value = ''

            setCanRealodAct(false)
            notiRef.current.style.display = 'none';

            setText('Deposit')
            setInconeName(faBitcoinSign)
            getbalance();

            setCurrentColor('darkorange')
          }, 5000);
        }
      } else {
        const addAllowance = await tokenInstance.methods.approve(contractInstance.options.address, 10000e18).send({ from: account });
        if (addAllowance) {
          const makeDeposit = await contractInstance.methods.deposit(amount).send({ from: account });
          if (makeDeposit) {
            console.log('Deposit successful');
            setText('Successfully completed');
            setInconeName(faCheckCircle);
            setCurrentColor('#00cb2c');
            depositRef.current.style.display = 'none';
            closeRef.current.style.display = 'none'
            amountRef.current.style.display = 'none'
            setCanRealodAct(true)
  
  
            setTimeout(() => {
              depositRef.current.style.display = 'inline';
              closeRef.current.style.display = 'inline'
              amountRef.current.style.display = 'inline'
              amountRef.current.value = ''

              notiRef.current.style.display = 'none';
              setCanRealodAct(false)
              setText('Deposit')
              setInconeName(faBitcoinSign)
              getbalance();
  
              setCurrentColor('darkorange')
            }, 5000);
          }
        }
      }
    } catch (error) {
      console.error("Error: ", error);
      setText('An error occurred');
      setInconeName(faExclamationCircle);
      setCurrentColor('red');
      setTimeout(() => {
        notiRef.current.style.display = 'none';
        setText('Deposit')
        setInconeName(faBitcoinSign)
        setCurrentColor('darkorange')
      }, 5000);
    }
  };

  const handelMax = async()=>{
    const max = ((Number(await tokenInstance.methods.balanceOf(account).call()))/1e18).toFixed(2);
    if (max > 0 ) {
      setvalue(max)
    }
   
  }

  const handleinput = (e)=>{
    const value = e.target.value ;
    setvalue(value)
  
  }

  return (
    <div ref={notiRef} className="noti" style={{ display: `${CanDisplay ? 'flex' : 'none'}`, margin: '30px', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
      <div className="noti-cont" style={{ background: 'transparent', padding: '50px', borderRadius: '30px' }}>
        <div className="noti-title" style={{ marginBottom: '10px', fontSize: '15px', fontWeight: 'bold', alignItems: 'center', textAlign: 'center' }}>
          <span style={{ fontSize: '1.4em' }}>{currentText}</span>
          <FontAwesomeIcon icon={currentInconName} style={{ width: '20px', color: currentColor, height: '1.4em' }} />
        </div>
        <div className="noti-content" style={{ fontSize: '10px' }}>
          <div className="amount" style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="amount" style={{ display: 'flex', justifyContent: 'left', margin: '5px' }}>Amount in USD</label>
            <input onChange={handleinput} value={value} placeholder="Enter the amount" ref={amountRef} type="number" name="amount" id="amountDeposit" style={{ padding: '10px', borderRadius: '10px', height:'55px', fontWeight:'bold' }} />
            <button ref={MaxRef} className='maxBtn' onClick={handelMax}>
              Max
            </button>

            <button style={{fontWeight: 'bold'}} className="depositBtn" ref={depositRef} onClick={handleDeposit}>Deposit         
</button>
            <button className="closeBtn" ref={closeRef} onClick={()=> notiRef.current.style.display = 'none' }>Close</button>

          </div>
        </div>
      </div>
      <style jsx>{`
        .amountDeposit:focus {
          outline: none;
          border-bottom: #0094df 1px solid;
        }
      `}</style>
    </div>
  );
};


const DisplayInputWithdraw = ({CanDisplayW,setDisplayBalance, setAvailableBalance,setCanRealodAct}) => {

  const { account, contractInstance } = useContext(Web3Context);
  const [currentText, setText] = useState('Withdrawal');
  const [currentInconName, setInconeName] = useState(faBitcoinSign);
  const [currentColor, setCurrentColor] = useState('darkorange');

  const amountRef = useRef();
  const depositRef = useRef();
  const notiRef = useRef();
  const closeRef = useRef();

    
    const getbalance = async()=> {
      const balances =  Number(await contractInstance.methods.userBalance(account).call());
      const blockedAmount = Number(await contractInstance.methods.TotalAmountBlocked(account).call());
      const userBalance = (balances) + (blockedAmount)
  
        setDisplayBalance((userBalance/1e18).toFixed(2))
        setAvailableBalance((balances/1e18).toFixed(2));
  
        console.log(balances)
        console.log(userBalance)
  
    }

  const handleWithdraw = async (e) => {
    e.preventDefault();
    try {
      const amount = Number(amountRef.current.value) * 1e18;
      console.log("Amount: ", amount);

        const balance = (Number(await contractInstance.methods.userBalance(account).call()))/1e18;

        if (balance > 0) { 

        const makewithdraw = await contractInstance.methods.withdraw(amount).send({ from: account });


        if (makewithdraw) {

        console.log('Withdraw successful');
            setText('Withdraw completed');
            setInconeName(faCheckCircle);
            setCurrentColor('#00cb2c');
            depositRef.current.style.display = 'none';
            closeRef.current.style.display = 'none'
            amountRef.current.style.display = 'none'
            setCanRealodAct(true)
  
            setTimeout(() => {
              setCanRealodAct(false)
              depositRef.current.style.display = 'inline';
              closeRef.current.style.display = 'inline'
              amountRef.current.style.display = 'inline'
              amountRef.current.value = ''

              notiRef.current.style.display = 'none';
  
              setText('Withdrawal')
              setInconeName(faBitcoinSign)
              getbalance();
  
              setCurrentColor('darkorange')
            }, 5000);
          
          }
  
        } else {
          setText('Balance is Empty');
          setInconeName(faExclamationCircle);
          setCurrentColor('red');

          setTimeout(() => {
            notiRef.current.style.display = 'none';
            setText('Withdrawal')
            setInconeName(faBitcoinSign)
            setCurrentColor('darkorange')
          }, 5000);
        }
      
    } catch (error) {
      console.error("Error: ", error);
      setText('An error occurred');
      setInconeName(faExclamationCircle);
      setCurrentColor('red');
      setTimeout(() => {
        notiRef.current.style.display = 'none';
        setText('Withdrawal')
        setInconeName(faBitcoinSign)
        setCurrentColor('darkorange')
      }, 5000);
    }
  };

  return (
    <div ref={notiRef} className="noti" style={{ display: `${CanDisplayW ? 'flex' : 'none'}`, margin: '30px', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, bottom: 0, left: 0, right: 0, zIndex: 900 }}>
      <div className="noti-cont" style={{ background: 'transparent', padding: '50px', borderRadius: '30px' }}>
        <div className="noti-title" style={{ marginBottom: '10px', fontSize: '15px', fontWeight: 'bold', alignItems: 'center', textAlign: 'center' }}>
          <span style={{ fontSize: '1.4em' }}>{currentText}</span>
          <FontAwesomeIcon icon={currentInconName} style={{ width: '20px', color: currentColor, height: '1.4em' }} />
        </div>
        <div className="noti-content" style={{ fontSize: '10px' }}>
          <div className="amount" style={{ display: 'flex', flexDirection: 'column' }}>
            <label htmlFor="amount" style={{ display: 'flex', justifyContent: 'left', margin: '5px' }}>Amount in USD</label>
            <input placeholder="Enter the amount" ref={amountRef} type="number" name="amount" id="amountDeposit" style={{ padding: '10px', borderRadius: '10px', height:'55px', fontWeight:'bold' }} />
            <button style={{fontWeight: 'bold'}} className="depositBtn" ref={depositRef} onClick={handleWithdraw}>Withdrawal         
</button>
            <button className="closeBtn" ref={closeRef} onClick={()=> notiRef.current.style.display = 'none' }>Close</button>

          </div>
        </div>
      </div>
      <style jsx>{`
        .amountDeposit:focus {
          outline: none;
          border-bottom: #0094df 1px solid;
        }
      `}</style>
    </div>
  );
};
  
export default BalanceCard;
