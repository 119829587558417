const currencies = [
    { value: 'AFN', label: 'AFN - Afghan Afghani' },
    { value: 'ALL', label: 'ALL - Albanian Lek' },
    { value: 'DZD', label: 'DZD - Algerian Dinar' },
    { value: 'AOA', label: 'AOA - Angolan Kwanza' },
    { value: 'ARS', label: 'ARS - Argentine Peso' },
    { value: 'AMD', label: 'AMD - Armenian Dram' },
    { value: 'AWG', label: 'AWG - Aruban Florin' },
    { value: 'AUD', label: 'AUD - Australian Dollar' },
    { value: 'AZN', label: 'AZN - Azerbaijani Manat' },
    { value: 'BSD', label: 'BSD - Bahamian Dollar' },
    { value: 'BHD', label: 'BHD - Bahraini Dinar' },
    { value: 'BDT', label: 'BDT - Bangladeshi Taka' },
    { value: 'BBD', label: 'BBD - Barbadian Dollar' },
    { value: 'BYN', label: 'BYN - Belarusian Ruble' },
    { value: 'BZD', label: 'BZD - Belize Dollar' },
    { value: 'BMD', label: 'BMD - Bermudian Dollar' },
    { value: 'BTN', label: 'BTN - Bhutanese Ngultrum' },
    { value: 'BOB', label: 'BOB - Bolivian Boliviano' },
    { value: 'BAM', label: 'BAM - Bosnia-Herzegovina Convertible Mark' },
    { value: 'BWP', label: 'BWP - Botswanan Pula' },
    { value: 'BRL', label: 'BRL - Brazilian Real' },
    { value: 'GBP', label: 'GBP - British Pound Sterling' },
    { value: 'BND', label: 'BND - Brunei Dollar' },
    { value: 'BGN', label: 'BGN - Bulgarian Lev' },
    { value: 'BIF', label: 'BIF - Burundian Franc' },
    { value: 'KHR', label: 'KHR - Cambodian Riel' },
    { value: 'CAD', label: 'CAD - Canadian Dollar' },
    { value: 'CVE', label: 'CVE - Cape Verdean Escudo' },
    { value: 'KYD', label: 'KYD - Cayman Islands Dollar' },
    { value: 'XAF', label: 'XAF - Central African CFA Franc' },
    { value: 'XPF', label: 'XPF - CFP Franc' },
    { value: 'CLP', label: 'CLP - Chilean Peso' },
    { value: 'CNY', label: 'CNY - Chinese Yuan' },
    { value: 'COP', label: 'COP - Colombian Peso' },
    { value: 'KMF', label: 'KMF - Comorian Franc' },
    { value: 'CDF', label: 'CDF - Congolese Franc' },
    { value: 'CRC', label: 'CRC - Costa Rican Colón' },
    { value: 'HRK', label: 'HRK - Croatian Kuna' },
    { value: 'CUP', label: 'CUP - Cuban Peso' },
    { value: 'CZK', label: 'CZK - Czech Republic Koruna' },
    { value: 'DKK', label: 'DKK - Danish Krone' },
    { value: 'DJF', label: 'DJF - Djiboutian Franc' },
    { value: 'DOP', label: 'DOP - Dominican Peso' },
    { value: 'XCD', label: 'XCD - East Caribbean Dollar' },
    { value: 'EGP', label: 'EGP - Egyptian Pound' },
    { value: 'ERN', label: 'ERN - Eritrean Nakfa' },
    { value: 'ETB', label: 'ETB - Ethiopian Birr' },
    { value: 'EUR', label: 'EUR - Euro' },
    { value: 'FJD', label: 'FJD - Fijian Dollar' },
    { value: 'GMD', label: 'GMD - Gambian Dalasi' },
    { value: 'GEL', label: 'GEL - Georgian Lari' },
    { value: 'GHS', label: 'GHS - Ghanaian Cedi' },
    { value: 'GIP', label: 'GIP - Gibraltar Pound' },
    { value: 'GTQ', label: 'GTQ - Guatemalan Quetzal' },
    { value: 'GNF', label: 'GNF - Guinean Franc' },
    { value: 'GYD', label: 'GYD - Guyanaese Dollar' },
    { value: 'HTG', label: 'HTG - Haitian Gourde' },
    { value: 'HNL', label: 'HNL - Honduran Lempira' },
    { value: 'HKD', label: 'HKD - Hong Kong Dollar' },
    { value: 'HUF', label: 'HUF - Hungarian Forint' },
    { value: 'ISK', label: 'ISK - Icelandic Króna' },
    { value: 'INR', label: 'INR - Indian Rupee' },
    { value: 'IDR', label: 'IDR - Indonesian Rupiah' },
    { value: 'IRR', label: 'IRR - Iranian Rial' },
    { value: 'IQD', label: 'IQD - Iraqi Dinar' },
    { value: 'ILS', label: 'ILS - Israeli New Sheqel' },
    { value: 'JMD', label: 'JMD - Jamaican Dollar' },
    { value: 'JPY', label: 'JPY - Japanese Yen' },
    { value: 'JOD', label: 'JOD - Jordanian Dinar' },
    { value: 'KZT', label: 'KZT - Kazakhstani Tenge' },
    { value: 'KES', label: 'KES - Kenyan Shilling' },
    { value: 'KWD', label: 'KWD - Kuwaiti Dinar' },
    { value: 'KGS', label: 'KGS - Kyrgystani Som' },
    { value: 'LAK', label: 'LAK - Laotian Kip' },
    { value: 'LBP', label: 'LBP - Lebanese Pound' },
    { value: 'LSL', label: 'LSL - Lesotho Loti' },
    { value: 'LRD', label: 'LRD - Liberian Dollar' },
    { value: 'LYD', label: 'LYD - Libyan Dinar' },
    { value: 'MOP', label: 'MOP - Macanese Pataca' },
    { value: 'MKD', label: 'MKD - Macedonian Denar' },
    { value: 'MGA', label: 'MGA - Malagasy Ariary' },
    { value: 'MWK', label: 'MWK - Malawian Kwacha' },
    { value: 'MYR', label: 'MYR - Malaysian Ringgit' },
    { value: 'MVR', label: 'MVR - Maldivian Rufiyaa' },
    { value: 'MRU', label: 'MRU - Mauritanian Ouguiya' },
    { value: 'MUR', label: 'MUR - Mauritian Rupee' },
    { value: 'MXN', label: 'MXN - Mexican Peso' },
    { value: 'MDL', label: 'MDL - Moldovan Leu' },
    { value: 'MNT', label: 'MNT - Mongolian Tugrik' },
    { value: 'MAD', label: 'MAD - Moroccan Dirham' },
    { value: 'MZN', label: 'MZN - Mozambican Metical' },
    { value: 'MMK', label: 'MMK - Myanmar Kyat' },
    { value: 'NAD', label: 'NAD - Namibian Dollar' },
    { value: 'NPR', label: 'NPR - Nepalese Rupee' },
    { value: 'ANG', label: 'ANG - Netherlands Antillean Guilder' },
    { value: 'TWD', label: 'TWD - New Taiwan Dollar' },
    { value: 'NZD', label: 'NZD - New Zealand Dollar' },
    { value: 'NIO', label: 'NIO - Nicaraguan Córdoba' },
    { value: 'NGN', label: 'NGN - Nigerian Naira' },
    { value: 'KPW', label: 'KPW - North Korean Won' },
    { value: 'NOK', label: 'NOK - Norwegian Krone' },
    { value: 'OMR', label: 'OMR - Omani Rial' },
    { value: 'PKR', label: 'PKR - Pakistani Rupee' },
    { value: 'PAB', label: 'PAB - Panamanian Balboa' },
    { value: 'PGK', label: 'PGK - Papua New Guinean Kina' },
    { value: 'PYG', label: 'PYG - Paraguayan Guarani' },
    { value: 'PEN', label: 'PEN - Peruvian Nuevo Sol' },
    { value: 'PHP', label: 'PHP - Philippine Peso' },
    { value: 'PLN', label: 'PLN - Polish Zloty' },
    { value: 'QAR', label: 'QAR - Qatari Rial' },
    { value: 'RON', label: 'RON - Romanian Leu' },
    { value: 'RUB', label: 'RUB - Russian Ruble' },
    { value: 'RWF', label: 'RWF - Rwandan Franc' },
    { value: 'SHP', label: 'SHP - Saint Helena Pound' },
    { value: 'WST', label: 'WST - Samoan Tala' },
    { value: 'STN', label: 'STN - São Tomé and Príncipe Dobra' },
    { value: 'SAR', label: 'SAR - Saudi Riyal' },
    { value: 'RSD', label: 'RSD - Serbian Dinar' },
    { value: 'SCR', label: 'SCR - Seychellois Rupee' },
    { value: 'SLL', label: 'SLL - Sierra Leonean Leone' },
    { value: 'SGD', label: 'SGD - Singapore Dollar' },
    { value: 'SBD', label: 'SBD - Solomon Islands Dollar' },
    { value: 'SOS', label: 'SOS - Somali Shilling' },
    { value: 'ZAR', label: 'ZAR - South African Rand' },
    { value: 'KRW', label: 'KRW - South Korean Won' },
    { value: 'SSP', label: 'SSP - South Sudanese Pound' },
    { value: 'LKR', label: 'LKR - Sri Lankan Rupee' },
    { value: 'SDG', label: 'SDG - Sudanese Pound' },
    { value: 'SRD', label: 'SRD - Surinamese Dollar' },
    { value: 'SZL', label: 'SZL - Swazi Lilangeni' },
    { value: 'SEK', label: 'SEK - Swedish Krona' },
    { value: 'CHF', label: 'CHF - Swiss Franc' },
    { value: 'SYP', label: 'SYP - Syrian Pound' },
    { value: 'TJS', label: 'TJS - Tajikistani Somoni' },
    { value: 'TZS', label: 'TZS - Tanzanian Shilling' },
    { value: 'THB', label: 'THB - Thai Baht' },
    { value: 'TOP', label: 'TOP - Tongan Paʻanga' },
    { value: 'TTD', label: 'TTD - Trinidad and Tobago Dollar' },
    { value: 'TND', label: 'TND - Tunisian Dinar' },
    { value: 'TRY', label: 'TRY - Turkish Lira' },
    { value: 'TMT', label: 'TMT - Turkmenistani Manat' },
    { value: 'UGX', label: 'UGX - Ugandan Shilling' },
    { value: 'UAH', label: 'UAH - Ukrainian Hryvnia' },
    { value: 'AED', label: 'AED - United Arab Emirates Dirham' },
    { value: 'UYU', label: 'UYU - Uruguayan Peso' },
    { value: 'UZS', label: 'UZS - Uzbekistani Som' },
    { value: 'VUV', label: 'VUV - Vanuatu Vatu' },
    { value: 'VES', label: 'VES - Venezuelan Bolívar Soberano' },
    { value: 'VND', label: 'VND - Vietnamese Dong' },
    { value: 'YER', label: 'YER - Yemeni Rial' },
    { value: 'ZMW', label: 'ZMW - Zambian Kwacha' },
    { value: 'ZWL', label: 'ZWL - Zimbabwean Dollar' },
  ];

  export default currencies;