import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import '../styles.css';

const ServiceBtn = ({navigate,typing }) => {

  if(typing) {
    return null;
  }
  
  return  (

    <div className="floating-button"   onClick={()=> navigate('/ServiceChat')}> 
      <FontAwesomeIcon icon={faComments} className="floating-icon" />
    </div>  
   ) ;

};

export default ServiceBtn;
