// src/components/Login.js
import React, { useContext } from 'react';
import { useEffect } from 'react';
import { Web3Context } from '../Web3Context';

import './Login.css';

const Login = ({ navigate }) => {
  return (
    <div className="App">
      <Header navigate={navigate} />
      <Signup navigate={navigate}/>
      <div className="registration-blur"></div>
      <Footer />
    </div>
  );
};

const Header = ({ navigate }) => {
    
  useEffect(() => {
    createRain();
  }, []);

  const createRain = () => {
    const numCoins = 20; // Number of icons
    const container = document.querySelector('.App');
    for (let i = 0; i < numCoins; i++) {
      const coin = document.createElement('div');
      coin.classList.add('coin');
      coin.style.left = `${Math.random() * 100}vw`;
      coin.style.animationDuration = `${Math.random() * 6 + 10}s`;
      container.appendChild(coin);
    }
  };
  return (
    <header className="App-header">
      <div className="header-container">
        <nav>
          <ul className="nav-links">
            <li><a href="#login" onClick={() => navigate('/login')}>Login</a></li>
            <li><a  href="#register" onClick={() => navigate('/Registration')}>Register</a></li>
            <li><a href="#home" onClick={() => navigate('/home')}>Home</a></li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

const Signup = ({ navigate }) => {
  const { account, contractInstance, secondContractInstance } = useContext(Web3Context);

  const checkIsRegistered = async () => {
    try {
      console.log('le compte ', account)
      console.log("l'instance", contractInstance)
      const isRegistered = await secondContractInstance.methods.isRegistered(account).call();
      if (isRegistered) {
        navigate('/dashboard');
      } else {
       navigate('/Register')
      }
    } catch (error) {
      console.error('Error checking registration status:', error);
    }
  };

  return (
    <div className="Login" style={{display:'flex',flexDirection:'column'}}>

    <div className="signup-containers">


      <div className="mono" style={{ fontSize: '2em', textAlign: 'center', marginTop: '10px', fontWeight: 600 , }}>
        WELCOME <br />
        <span className="PARADIZ" style={{ color: '#66ccff' }}>
          Da<span className="PARADIZ" style={{ opacity: '50%' }}>ni</span>el
        </span>
      </div>
      <main className="signup-mains" >
        <h1 style={{fontSize:'20px', color:'white'}} className="signup-title" >Login Automatic</h1>
          <button type="submit" className="signup-button" onClick={checkIsRegistered}>Login 
</button>
        <p>
          Not registered yet? Click <a style={{ textDecoration: 'none' }} href="#register" onClick={() => navigate('/Registration')}><span style={{ color: '#66ccff' }}>here</span></a>
        </p>
      </main>
    </div>
    <div className="Login-Right">
    </div>

    </div>

  );
};

const Footer = () => {
  return (
    <footer style={{ textAlign: 'center' }} className="App-footer">
      <p>© 2024 Your Company. All rights reserved.</p>
    </footer>
  );
};

export default Login;
