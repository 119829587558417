import React, { forwardRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faChartLine, faMessage, faUser, faChartBar, faSignOutAlt, faBitcoinSign , faExchange } from '@fortawesome/free-solid-svg-icons';
import '../styles.css';
import logo from '../Para-logo.svg';

const Sidebar = forwardRef(({ open, navigate , element,DashEle,marketEle,OrdersEle , profile}, ref) => {
  console.log(marketEle)
  return (
    <div ref={ref} style={{ zIndex: 1000, position: 'fixed' }} className={`sidebar ${open ? 'open' : ''}`}>
      <div className="blur" style={{ right: '80%' }}></div>

      <div className="sidebar-header">
        <img src={logo} alt="Company Logo" className="sidebar-logo" />
        <h1 className="sidebar-company-name">Paradiz</h1>
      </div>

      <div className="sidebar-content">
        <ul className="sidebar-list">
          <li className="sidebar-list-item" onClick={() => navigate('/Dashboard')}>
            <FontAwesomeIcon icon={faHome} className="sidebar-list-icon" />
            <span className="sidebar-list-text" style={{ color: `${DashEle}`}}>Dashboard</span>
          </li>
          <li className="sidebar-list-item" onClick={() => navigate('/P2PMarket')}>
            <FontAwesomeIcon icon={faChartLine} className="sidebar-list-icon" />
            <span className="sidebar-list-text" style={{ color: `${marketEle}`}}>P2P Market</span>
          </li>
          <li className="sidebar-list-item" onClick={() => navigate('/Orders')}>
            <FontAwesomeIcon icon={faChartBar} className="sidebar-list-icon" />
            <span className="sidebar-list-text" style={{ color:  `${OrdersEle}` }}>Orders</span>
          </li>
          <li className="sidebar-list-item" onClick={() => navigate('/ChatPage')}>
            <FontAwesomeIcon icon={faMessage} className="sidebar-list-icon" />
            <span className="sidebar-list-text" style={{color:`${element}`}}>Live Chat</span>
          </li>
          <li className="sidebar-list-item" onClick={() => navigate('/Interacted')}>
            <FontAwesomeIcon icon={faExchange} className="sidebar-list-icon" />
            <span className="sidebar-list-text" style={{color:'white'}}>Interacted Orders</span>
          </li>
          <li className="sidebar-list-item" onClick={() => navigate('/CloudyRewards')}>
            <FontAwesomeIcon icon={faBitcoinSign} className="sidebar-list-icon" />
            <span className="sidebar-list-text" style={{color:'white'}}>Cloudy Rewards</span>
          </li>
          <li className="sidebar-list-item" onClick={() => navigate('/Profile')}>
            <FontAwesomeIcon icon={faUser} className="sidebar-list-icon" />
            <span className="sidebar-list-text" style={{ color: `${profile}` }}>Profile</span>
          </li>
      
          <li className="sidebar-list-item" onClick={() => navigate('/logout')}>
            <FontAwesomeIcon icon={faSignOutAlt} className="sidebar-list-icon" />
            <span className="sidebar-list-text" style={{color:'white'}}>Log out</span>
          </li>
        </ul>
      </div>
    </div>
  );
});

export default Sidebar;
