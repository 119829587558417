import React, { useState } from 'react';
import { Web3Context } from '../Web3Context';
import { useEffect } from 'react';
import { useContext } from 'react';

import bnbImg from '../bnb.svg';
import tetherImg from '../tether.svg';
import solImg from '../sol.svg';
import etherImg from '../ether.svg';
import img22 from '../22.svg';
import imgDec from '../dec.svg';
import imgCon from '../_con.972df497ac83a2827bb4.webp';
import imgSec from '../sec.svg';
import img23 from '../23.svg';
import './index.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

function AppHtml({ navigate }) {
  return (
    <div>
            <Notification  />
            <DisconnectNot  />


      <div className="blur"></div>
      

      <div className="rippling-effect">
        <div className="bnbImg">
          <img className="bnb" style={{ top: '10%' }} src={bnbImg} alt="BNB logo" />
        </div>
        <div className="tetherImg">
          <img className="tether" style={{ top: '20%' }} src={tetherImg} alt="Tether logo" />
        </div>
        <div className="solImg">
          <img className="sol" style={{ top: '10%' }} src={solImg} alt="Sol logo" />
        </div>
        <div className="etherImg">
          <img className="ether" style={{ top: '20%' }} src={etherImg} alt="Ether logo" />
        </div>
      </div>
      <header className="App-header">
        <div className="header-container">
          <nav>
            <ul className="nav-links">
              <li><a href="#login" onClick={() => navigate('/login')}>Login</a></li>
              <li><a  href="#register" onClick={() => navigate('/Registration')}>Register</a></li>
              <li><a href="#home">P2P</a></li>
            </ul>
          </nav>
        </div>
        <div className="hero-section">
          <h1 className="titre">
            <span className="TC" style={{color: 'white', fontSize:'0.7em'}}>WELCOME TO</span>
            <span>
              <span className="paradiz" style={{fontStyle:'italic'}}> PA<span style={{opacity:0.5}}>RA</span>DIZ</span>
              <span className="exchange"  style={{color: 'white', fontSize:'0.7em'}}> EX<span className="chan" style={{color:'white'}}>CHAN</span>GE</span>
            </span>
          </h1>
          <p style={{ display: 'inline-block' }}>
            Non-custodial Bitcoin trading<br />
            solution <span style={{ fontWeight: 'bold' }}>with smart contracts.</span>
          </p>
          <div className="hero-buttons">
            <button className="hero-signup" onClick={() => navigate('/Registration')}>Sign up</button>
            <button className="hero-info">What is P2P?</button>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', height: '200px' }}>
          <img src={img22} alt="Decorative" className="img" />
        </div>
        <div className="blurs"></div>
        <div className="team-section">
          <h2>Benefits</h2>
          <div className="team-members">
            <div className="team-member">
              <img src={imgDec} alt="Decentralized" />
              <h3 style={{ color: '#66ccff', fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif' }}>DECENTRALIZED</h3>
              <p>
                Decentralization in peer-to-peer (P2P) trading<br />
                systems provides enhanced security,<br />
                reduces the risk of single points<br />
                of failure, and promotes greater<br />
                transparency and autonomy.
              </p>
            </div>
            <div className="team-member">
              <img src={imgCon} alt="Smart Contract" />
              <h3 style={{ color: '#66ccff', fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif' }}>Smart Contract</h3>
              <p>
                Smart contracts are programs stored on a<br />
                blockchain that execute automatically when<br />
                predefined conditions are met.
              </p>
            </div>
            <div className="team-member">
              <img src={imgSec} alt="Secure" />
              <h3 style={{ color: '#66ccff', fontFamily: 'Segoe UI, Tahoma, Geneva, Verdana, sans-serif' }}>SECURE</h3>
              <p>
                Security through blockchain technology:<br />
                Smart contracts benefit from the intrinsic<br />
                security of blockchains, which use distributed<br />
                consensus mechanisms to validate transactions.
              </p>
            </div>
          </div>
        </div>
        <div className="stats">
          <div style={{ display: 'flex', justifyContent: 'center', position: 'relative', height: '200px' }}>
            <img src={img23} alt="Decorative" className="img" />
          </div>
          <div className="stat-item">
            <h3 className="H">7 YEARS</h3>
            <p>in business</p>
          </div>
          <div className="stat-item">
            <h3 className="H">100+</h3>
            <p>currencies</p>
          </div>
          <div className="stat-item">
            <h3 className="H">50 000+</h3>
            <p>deals closed</p>
          </div>
          <div className="stat-item">
            <h3 className="H">100 000+</h3>
            <p>registered users</p>
          </div>
        </div>
        <div className="contact-section">
          <h2>Leave Us a Comment</h2>
          <form className="contact-form">
            <label htmlFor="email">Email:</label>
            <input type="email" id="email" name="email" required />
            <label htmlFor="message">Message:</label>
            <textarea id="message" name="message" rows="4" required></textarea>
            <button type="submit">Send</button>
          </form>
        </div>
      </header>
      <footer className="App-footer" style={{ textAlign: 'center' }}>
        <p>© 2024 Your Company. All rights reserved.</p>
      </footer>
    </div>
  );
}

function Notification() {
  const [isConnected , setIsConnected] = useState(true)
  

  const { account } = useContext(Web3Context);

  useEffect(() => {
 account ? setIsConnected(true) : setIsConnected(false)
   setTimeout(() =>  {
    setIsConnected(false)
   }, 5000)
    }, [account, setIsConnected]);

  return (
    <div  className="noti" style={{ display: `${isConnected ? 'flex' : 'none'}` ,margin:'10px', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 , zIndex:900}}>

      <div className="noti-cont" style={{ background: 'transparent', padding: '30px', borderRadius: '30px' }}>

        <div className="noti-title" style={{ marginBottom: '10px', fontSize: '15px', fontWeight: 'bold', alignItems: 'center', textAlign: 'center'}}>

          Connected To Web3 <FontAwesomeIcon icon={faCheckCircle} style={{ marginLeft: '10px', color: 'lime' }} />
        </div>
        <div className="noti-content" style={{ fontSize: '10px' }}>
          <p style={{fontSize:'10px'}}>You are currently<span style={{color :'lime', fontWeight:400}}> connected</span> <br/> you can get started by clicking on login</p>
        </div>
      </div>
    </div>
  );
}

  function DisconnectNot() {
  const [isConnected , setIsConnected] = useState(true)
  

  const { account } = useContext(Web3Context);

  useEffect(() => {
  const awaitConnexion = () => { account ? setIsConnected(true) : setIsConnected(false) }

   setTimeout(() =>  {
    setIsConnected(true)
   }, 5000)

awaitConnexion()

    }, [account, setIsConnected]);


  return (
    <div  className="noti" style={{ display: `${!isConnected ? 'flex' : 'none'}` ,margin:'10px', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 , zIndex:900}}>

      <div className="noti-cont" style={{ background: 'transparent', padding: '30px', borderRadius: '30px' }}>

        <div className="noti-title" style={{ marginBottom: '10px', fontSize: '15px', fontWeight: 'bold', alignItems: 'center', textAlign: 'center'}}>

          Not Connected To Web3 <FontAwesomeIcon icon={faExclamationCircle} style={{ marginLeft: '10px', color: 'red' }} />
        </div>
        <div className="noti-content" style={{ fontSize: '10px' }}>
          <p style={{fontSize:'10px'}}>You are not <span style={{color :'red', fontWeight:400}}> connected</span> <br/> you can read the public information of the contract.</p>
        </div>
      </div>
    </div>
  );
}
export default AppHtml;
