import React from 'react';

const Avatar = ({ name }) => {
  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : '';
  };

  return (
    <div className="avatar">
      {getInitial(name)}
      <style jsx> {
        `.avatar {
            width: 65px;
            height: 65px;
            border-radius: 50%;
            background-color: #00bfff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.5em;
            color: white;
            font-weight: bold;
            text-transform: uppercase;
          }
          `
      }
        
          </style>

    </div>
  );
};

export default Avatar;
