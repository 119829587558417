import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const AddOrderItem = ({navigate}) => {
  return (
    <div className="order-item add-order-item"   onClick={()=> navigate('/AddOrder')} style={{marginTop:'40px'}}>
      <div className="add-order-icon" >
        <FontAwesomeIcon icon={faPlusCircle} />
      </div>
      <div className="add-order-text">
        Add Order
      </div>
    </div>
  );
};

export default AddOrderItem;
