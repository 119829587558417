import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faMessage } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useContext } from 'react';
import { Web3Context } from '../Web3Context';

import '../styles.css';

const OrderStatus = ({navigate}) => {
  const { account, contractInstance, secondContractInstance , tokenInstance} = useContext(Web3Context);

  const [isInOrder, setisInOrder] = useState(true)

  useEffect(() => {
    const orderChecker = async () => {
      const isInTrade = await contractInstance.methods.isInTrade(account).call() ;
      if (isInTrade) {
      setisInOrder(!isInOrder)
    }
      console.log('isInTrade', isInTrade) 

      }

      const isInTrade = async () =>  {
        try {

          const isInTrade =  await secondContractInstance.methods.isInTrade(account).call();
          if (isInTrade) { 

            setisInOrder(true)

          } else {
            setisInOrder(false)
          }
          
        } catch (error) {
          
        }
      } 

      isInTrade()
      orderChecker()
  }, [account, secondContractInstance])

  return (
    <div className="order-status-card">
      <div className="order-status-header">
        <h2 className="order-status-title">Order Status</h2>

        <FontAwesomeIcon
          icon={isInOrder ? faCheckCircle : faExclamationCircle}
          className={`order-status-icon ${isInOrder ? 'icon-active' : 'icon-inactive'}`}
        />

      </div>
      {isInOrder ? (
        <div className="order-status-message">
        <p> Not currently in Order, do you want to sell or buy cryptos ?</p>

          <button className="release-button" onClick={()=> navigate('/AddOrder')}>Add an Order</button>
        </div>
      ): (
        <div className="order-status-message">
          <p>You are currently in a trade, chat with the counterparty</p>

          <button className="release-button" onClick={()=> navigate('/ChatPage')}>Chat  <FontAwesomeIcon
          icon={faMessage}
          
        /> </button>
        </div>
      ) }
      <style jsx>{`
        .order-status-card {
          color: #ffffff;
          padding: 20px;
          border-radius: 30px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          margin-left: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }

        .order-status-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .order-status-title {
          font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
          margin: 0;
        }

        .order-status-icon {
          font-size: 2rem;
        }

        .icon-active {
          color: #66ccff;
        }

        .icon-inactive {
          color: #ff6347;
        }

        .order-status-message {
          margin-top: 20px;
        }

        .release-button {
          background-color: #008ed0;
          border: none;
          color: #ffffff;
          padding: 10px 20px;
          border-radius: 10px;
          cursor: pointer;
          font-size: 1rem;
        }

        .release-button:hover {
          background-color: transparent;
          border: 1px solid white;
        }
      `}</style>
    </div>
  );
};

export default OrderStatus;
