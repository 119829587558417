import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBitcoinSign,faWallet,faArrowDown,faArrowUp, faTimes , faCoins} from '@fortawesome/free-solid-svg-icons';
import '../styles.css';
import { Web3Context } from '../Web3Context';
import web3 from '../Web3.js/web3';

const RecentActivities = () => {
  const { contractInstance } = useContext(Web3Context);
  const [activities, setActivities] = useState([]);
  console.log("render RecentActivities");

  useEffect(() => {
    const fetchRecentActivities = async () => {
      const currentBlock = await web3.eth.getBlockNumber();
      const block = Number(currentBlock) - 1000;
      console.log('starting fectching')
      try {
    
        const events = await contractInstance.getPastEvents('allEvents', {
          fromBlock:block,
          toBlock: 'latest'
        });

        console.log('events', events);

        const recentEvents = events.slice(-3).map(event => {
          let icon;
          let statut = true;
          let name ;
          switch (event.event) {
            case 'DepositMade':
              icon = faCoins;
              name = 'Deposit'
              break;
            case 'WithdrawalMade':
              icon = faWallet;
              name = 'Withdraw'

              break;
            case 'SellOrderPlaced':
              icon = faArrowUp;
              name = 'Sell'
              break;
            case 'BuyOrderPlaced':
              icon = faArrowDown;
              name = 'Buy'
              break;
            default:
              icon = faTimes;
              statut = false;
              name = 'no Data'
          }
          return {
            id: event.id,
            activity: ` ${((Number(event.returnValues.amount))/1e18).toFixed(2) || ((Number(event.returnValues.amountBlockedForSell))/1e18).toFixed(2) || ((Number(event.returnValues.amountBlockedForBuy))/1e18).toFixed(2)} $`,
            date: new Date(event.returnValues.timestamp * 1000).toLocaleDateString(),
            icon,
            statut,
            name
          };
        });

        setActivities(recentEvents);
        
      } catch (error) {
        console.error('Error fetching recent activities:', error);
        alert(error)
      }
    };

   
    fetchRecentActivities();

  }, [contractInstance]);

  return (
    <div className="card recent-activities-card" style={{ backgroundSize: 'cover' }}>
      <h2>Platform Activities</h2>
      <ul className="activities-list">
        {activities.map(activity => (
       
             <li key={activity.id} className="activity-item" style={{display:'block'}}>
                           <div className="activity-d" style={{display:'flex'}}>

                           <div className='faIncon'>

                          <FontAwesomeIcon icon={activity.icon} style={!activity.statut ? { color: 'red' } : { color: 'darkorange' , width:'20px'}} className="activity-icon" />
                          </div>

             <div className="activity-details" style={{display:'block'}}>
             <div className='dets'>

               <span className="activity-description" style={{color:'darkorange'}}>{activity.name} </span>
             <div className='actAmount' style={{marginTop:0,marginBottom:0}}>
             <p className="Amount" style={{marginTop:'1px',marginBottom:'1px'}}>{activity.activity}</p>
             </div>
             </div>
             </div>

             </div>
 
           </li>
        ))}
      </ul>
      <style jsx>{`
        .recent-activities-card {
          color: #ffffff;
          padding: 20px;
          border-radius: 20px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
        }

        .activities-list {
          list-style: none;
          padding: 0;
        }

        .activity-item {
          display: flex;
          align-items: center;
          margin-bottom: 4px;
        }

        .activity-icon {
          font-size: 1.5rem;
          margin-right: 10px;
        }

        .activity-details {
          display: flex;
          flex-direction: column;
        }

        .activity-description {
          font-size: 1rem;
          font-weight: 600;
        }

        .activity-date {
          font-size: 0.9rem;
          color: #999;
        }
      `}</style>
    </div>
  );
};

export default RecentActivities;
