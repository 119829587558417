import React, { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faShoppingCart, faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import '../styles.css';
import { Web3Context } from '../Web3Context';

const TransactionsTable = () => {

  const { account,contractInstance, secondContractInstance } = useContext(Web3Context);

  const [users, updateUsers] = useState(0)
  const [sales, updateSales] = useState(0)
  const [purchases, updatePurchases] = useState(0)
  const [deposits, updateDeposits] = useState(0)




  useEffect( () => {
    const  getNumberOfUsers= async ()=>  {
     const numberOfUsers = await secondContractInstance.methods.numberOfUsers().call();
     const numberOfPurchases = await contractInstance.methods.numberOfPurchases().call();
     const totalAmountDeposit = await contractInstance.methods.totalAmountDeposit().call();
     const NumberOfSales = await contractInstance.methods.NumberOfSales().call();

     updateUsers(Number(numberOfUsers))
     updateSales(Number(NumberOfSales))
     updatePurchases(Number(numberOfPurchases))
     updateDeposits(((Number(totalAmountDeposit))/1e18).toFixed(2))
     console.log(numberOfUsers)

    }

    getNumberOfUsers()
    
  }, [account,secondContractInstance]);


  const statistics = [
    { id: 1, icon: faUsers, label: 'Participants', value: users},
    { id: 2, icon: faShoppingCart, label: 'Sales', value: sales },
    { id: 3, icon: faArrowDown, label: 'Purchases', value: purchases },
    { id: 4, icon: faArrowUp, label: 'Deposits', value: deposits },
  ];

  return (
    <div className="card transactions-summary">
      <h2 style={{marginLeft:'10px'}}>Statistics</h2>
      <div className="statistics-grid">
        {statistics.map(stat => (
          <div key={stat.id} className="statistic-card">
            <FontAwesomeIcon icon={stat.icon} className="statistic-icon" />
            <div className="statistic-details">
              <span className="statistic-label">{stat.label}</span>
              <span className="statistic-value">{stat.value}</span>
            </div>
          </div>
        ))}
      </div>
      <style jsx> { `
      /* styles.css */

.transactions-summary {
  background-color: rgba(17, 17, 17, 0.699);
  color: #ffffff;
  padding: 5px;
  border-radius: 20px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  width: 100%;
  font-size: small;
  transition: transform 0.3s, box-shadow 0.3s;
}

.transactions-summary h2 {
  font-size: 1rem;
  margin-bottom: 20px;
}

.statistics-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-left:10px
}

.statistic-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 8px;
  display: flex;
  align-items: center;
  width: calc(50% - 10px);
  transition: transform 0.3s, box-shadow 0.3s;
}

.statistic-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}

.statistic-icon {
  font-size: 1rem;
  margin-right: 5px;
  color: #66ccff;
}

.statistic-details {
  display: flex;
  flex-direction: column;
}

.statistic-label {
  font-size: 1rem;
  font-weight: 400;
  color:white;
  margin-left: 10px;
}

.statistic-value {
  color: #66ccff;
  font-weight: bolder;
  margin-left: 10px;


}`}
</style>

    </div>
  );
};

export default TransactionsTable;
