import Select from 'react-select';
import React from 'react';
import { useState } from 'react';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash , faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import currencies from './currencies/currencies';
import { useEffect } from 'react';
import { useContext } from 'react';
import { Web3Context } from '../Web3Context';
import { useRef } from 'react';
import { m } from 'framer-motion';
 
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#1e1e2d',
      borderColor: '#3d3d52',
      color: '#ffffff',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#ffffff',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#1e1e2d',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#3d3d52' : '#1e1e2d',
      color: '#ffffff',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#ffffff',
    }),
  };
  
 

  const BuyOrder = ({navigate}) => {
    const [selectedCurrency, setSelectedCurrency] = useState('')
    const [amountToMax , setAmMax] = useState(0)
    const [amountToMin , setAmMin] = useState(0)
    const [max , setMax] = useState(0)

    console.log('amountToMax',amountToMax)
   console.log(selectedCurrency)
   const handeCurrencyChanges= (selectOption)=>  {
     setSelectedCurrency(selectOption) 
    }
    const AmountRef =  useRef()
    const MinRef =  useRef()
    const MaxRef =  useRef()
    const RateRef =  useRef()
    const DeviseRef =  useRef()

   const publish = async (e) =>  {
    e.preventDefault();
    try {
      
  

   
    const AmountTo = (Number(AmountRef.current.value))*1e18;
    const Min = ( Number( MinRef.current.value))*1e18;
    const Max = (Number(MaxRef.current.value))*1e18;
    const Rate = (Number(RateRef.current.value));
    const Devise = selectedCurrency.value;
    console.log('Devise',Devise,
         'Min', Min, 'max', Max
    )
    
    console.log(Devise)

    const Response = await contractInstance.methods.placeBuyOrder(AmountTo,Min,Max,Rate,Devise).send({from: account})
   if (Response) {
    console.log('success')
    navigate('/Orders')
   }
} catch (error) {
       console.log(error)
}
    }
  
 
  


    const { account, contractInstance, secondContractInstance , tokenInstance} = useContext(Web3Context);
  
    const [paymentPairs, setPaymentPairs] = useState([]);
    const [newPaymentMethod, setNewPaymentMethod] = useState('');
    const [newPaymentInfo, setNewPaymentInfo] = useState('');
    const [Devise, setDevise] = useState('');

    const handleAddPaymentPair = () => {
      if (newPaymentMethod && newPaymentInfo) {
        setPaymentPairs([...paymentPairs, { method: newPaymentMethod, info: newPaymentInfo }]);
        setNewPaymentMethod('');
        setNewPaymentInfo('');
      }
    };
  
    const handleRemovePaymentPair = (index) => {
      const updatedPairs = paymentPairs.filter((_, i) => i !== index);
      setPaymentPairs(updatedPairs);
    };
  
    return (
      <div className="order-form">
        <h2 className="nameBuy" id='buyID' style={{ color: 'white' }}>Buy Order    </h2>
        <form onSubmit={(e)=> publish}>
          <label>
            Amount To Buy:
            <input ref={AmountRef} onChange={(e)=> setAmMax(e.target.value)} className="inp1" type="number" name="_AmountToBuy" />
          </label>
          <label>
            Min USDT:
            <input ref={MinRef} onChange={(e)=> setAmMin(e.target.value)} style={{border: `${amountToMin > amountToMax ? 'red 1px solid' : ''}`}} max={amountToMax}  required className="inp1" type="number" name="_minUSDT" />
          </label>
          <label>
            Max USDT:
            <input ref={MaxRef} max={amountToMax}  onChange={(e)=> setMax(e.target.value)}  style={{border: `${max > amountToMax ? 'red 1px solid' : ''}`}} required className="inp1" type="number" name="_maxUSDT" />
          </label>
          <label>
            Rate:
            <input ref={RateRef} required className="inp1" type="number" name="_rate" />
          </label>
          <label>
            Devise:
            <Select ref={DeviseRef} 
            onChange={handeCurrencyChanges}
            value={selectedCurrency}
             options={currencies}
             styles={customStyles} className="select-currency" />
          </label>
          <div>
            <label>
              New Payment Method:
              <input
                className="inp1"
                type="text"
                value={newPaymentMethod}
                onChange={(e) => setNewPaymentMethod(e.target.value)}
              />
            </label>
            <label>
              New Payment Info:
              <input
                className="inp1"
                type="text"
                value={newPaymentInfo}
                onChange={(e) => setNewPaymentInfo(e.target.value)}
              />
            </label>
            <button type="button" style={{ display: 'flex' }} className="AddP" onClick={handleAddPaymentPair}>
              <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '10px' }} />
              Add
            </button>
          </div>
          <div>
            <h3>Payment Methods & Infos:</h3>
            <ul>
              {paymentPairs.map((pair, index) => (
                <li key={index}>
                     <span style={{fontWeight:'bold'}}>Method : </span> {pair.method} -  <span  style={{fontWeight:'bold'}}> Info :</span> {pair.info}
                  <p className='trash' style={{ padding:'10px', border:'1px solid white', width:'30px', height:'30px', display:'flex', justifyContent:'center'}} onClick={() => handleRemovePaymentPair(index)}>
                    <FontAwesomeIcon icon={faTrash} />
                  </p>
                </li>
              ))}
            </ul>
          </div>
          <div className="btnO">
            <button className="SubBuy" onClick={publish}>Publish</button>
          </div>
        </form>
      </div>
    );
  } 

  

  
function RegistrationNoti({isOK, navigate}) { 

  const { account, contractInstance } = useContext(Web3Context);
  const [username, setUsername] = useState('')
  const [canDisplay, setCandisplay] = useState(false)
  
    useEffect( ()=> {
       const setDisplay =  async () => {
        if (isOK) {

        const User = await contractInstance.methods.name(account).call();
        console.log('last fonction', isOK )
  
          setCandisplay(true)
          setUsername(User)
        } else {
          setCandisplay(false)
        }
       }
       setDisplay()
  
       setTimeout(() => {
            if(isOK) {
              setCandisplay(false)

            navigate('/Dashboard')
          }
       }, 5000);
  
    } , [setCandisplay, isOK, navigate, account, contractInstance])
  
    return (
      <div  className="noti" style={{ display: `${canDisplay ? 'flex' : 'none'}` ,margin:'10px', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 , zIndex:900}}>
  
        <div className="noti-cont" style={{ background: 'transparent', padding: '30px', borderRadius: '30px' }}>
  
          <div className="noti-title" style={{ marginBottom: '10px', fontSize: '15px', fontWeight: 'bold', alignItems: 'center', textAlign: 'center'}}>
  
          Registration successfully <FontAwesomeIcon icon={faCheckCircle} style={{ marginLeft: '10px', color: 'lime' }} />
          </div>
          <div className="noti-content" style={{ fontSize: '10px' }}>
            <p style={{fontSize:'10px', color:'black'}}>Welcome Dear<span style={{color :'lime', fontWeight:400}}> {username}</span> <br/></p>
          </div>
        </div>
      </div>
    );
  }
  

  export default BuyOrder;