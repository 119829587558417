import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import '../styles.css';  // Assuming you have global styles here or adjust the path accordingly
import './global.css'; // Separate CSS for P2P Market

const SellUSDT = ({ navigate }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [marketEle, setmarketEle] = useState('white');
  const [SpaceName, setSpaceName] = useState('Dashboard');
  const [paymentMethod, setPaymentMethod] = useState('');
  const sidebarRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };

    // Set initial state based on window size
    handleResize();

    // Add event listeners
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Clean up event listeners
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === '/SellUsdt') {
      console.log('ok')
      setmarketEle('aqua');
      setSpaceName('Place Order');
    } else {
      setmarketEle('white');
      setSpaceName('Dashboard');
    }
  }, [location,setmarketEle,setSpaceName]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="sell-usdt-page">
      <Sidebar ref={sidebarRef} open={sidebarOpen} navigate={navigate} marketEle={marketEle} />
      <div className="content">
      <Topbar toggleSidebar={toggleSidebar} navigate={navigate} SpaceName={SpaceName} />
        <div className="sell-usdt">
          <div className="header">
            <h2>Sell USDT</h2>
            <p className="price" style={{ fontWeight: 'bolder', color: '#66ccff' }}>$1,100 / USD</p>
          </div>
          <div className="sell-form">
            <div className="tab-buttons">
              <button className="tab-button active">Payment Process</button>
            </div>
            <div className="form-group">
              <input type="text" placeholder="Enter amount" className="form-control" />
              <button className="all-button">All</button>
            </div>
            <p className="limits">Limit USD 99,999.00 - USD 150,000.00</p>
            <p className="balance">Balance 0.00 USDT <span className="balance-icon">+</span></p>
            <div className="form-group">
              <select value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} className="form-control">
                <option className="EnterOption" value="">Select Payment Method</option>
                <option className="EnterOption" value="bank">Bank Transfer</option>
                <option className="EnterOption" value="paypal">PayPal</option>
                <option className="EnterOption" value="crypto">Crypto Transfer</option>
              </select>
            </div>
            <p className="sell-amount">You Sell - USDT</p>
            <p className="processing-fee">Processing Fee - USDT</p>
          </div>
          <div className="advertiser-info">
            <p>Advertiser's Status <span className="last-seen">Last seen 4 hour(s) ago</span></p>
            <p>Payment Time Limit <span className="time-limit">3 hr</span></p>
          </div>
          <div className="terms-section">
            <div className="tabs">
              <button className="tab active">Infos</button>
              <button className="tab">Feedback</button>
            </div>
            <div className="terms-content">
              <p>We only trade amounts of 1000 USDT or more</p>
              <p>🌞 Happy TRADE 👍👍🎉</p>
              <p>Telegram: @tieuphung1409</p>
              <p>Wechat: doanlam1409</p>
              <p>WhatsApp: +84389563959</p>
              <p>WhatsApp: +84389642895</p>
            </div>
          </div>
          <button className="sell-button">Sell USDT</button>
        </div>
      </div>
    </div>
  );
};

export default SellUSDT;
