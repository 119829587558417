import React from 'react';

const OrderItem = ({type,status, devise, isOrderAvailableYet, paymentMethods, rate, timestamp, totalAmount }) => {
  console.log('data',devise ,isOrderAvailableYet , rate)
  
  return (
    <div className="order-item" style={{marginTop:'40px'}}>
      <div className="order-header">
        <div className="order-type">
          <span className={`order-type-indicator ${type.toLowerCase()}`}>{type}</span> • USDT
        </div>
        <div className={`order-status ${status.toLowerCase()}`}>{status}</div>
      </div>
      <div className="order-detail">
        <p><strong>Price:</strong> {Number(rate)} <strong> {devise}</strong> / <strong>USDT</strong> </p> 
        <p><strong>statut:</strong>  {isOrderAvailableYet ? 'Available' : ' Unavailable'}</p>
        <p><strong>Trading amount:</strong>  {((Number(totalAmount))/1e18).toFixed(2) * Number(rate)} {devise} </p>
        <p><strong>Payment method:</strong> {paymentMethods}</p>
        <p className="order-date">{timestamp}</p>
        <div className='btnArray'>
          <button className='show'>Show Details</button>
        </div>
      </div>
    </div>
  );
};

export default OrderItem;
