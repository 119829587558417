import { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import SellOrder from "./SellOrder";
import BuyOrder from "./BuyOrder";

const AddOrder = ({ navigate }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [OrdersEle, setAddOrderEle] = useState('white');
  const [SpaceName, setSpaceName] = useState('Dashboard');
  const [isSellOrder, setIsSellOrder] = useState(true);
  console.log("render AddOrder");

  const sidebarRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === '/AddOrder') {
      setAddOrderEle('aqua');
      setSpaceName('Place Order');
    } else {
      setAddOrderEle('white');
      setSpaceName('Add Order');
    }
  }, [location, setAddOrderEle, setSpaceName]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <Sidebar ref={sidebarRef} open={sidebarOpen} navigate={navigate} OrdersEle={OrdersEle} />
      <Topbar toggleSidebar={toggleSidebar} navigate={navigate} SpaceName={SpaceName} />
      
      <div className="content">

      <div className="add-order-container">
        <div className="order-toggle">
          <button onClick={() => setIsSellOrder(true)} className={isSellOrder ? 'active' : ''}>Sell Order</button>
          <button onClick={() => setIsSellOrder(false)} className={!isSellOrder ? 'actives' : ''}>Buy Order</button>
        </div>
        {isSellOrder ? <SellOrder navigate={navigate} /> : <BuyOrder navigate={navigate} />}
      </div>
      <div >
      </div>
      </div>

    </>
  );
}

export default AddOrder;
