import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart, faCheckCircle, faThumbsUp, faThumbsDown, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import Avatar from './Avatar';
import './global.css';
import { useEffect, useRef  } from 'react';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { Web3Context } from '../Web3Context';


const ProfileSettings = ({ navigate }) => {

  const { account, contractInstance, secondContractInstance } = useContext(Web3Context);
  const [merchant , setMerchant] = useState({
    name : '',
    email : '',
    phone    : '',
    BusName  : '',
    DesC     :'',
    country : ''
  })

    const location = useLocation();
    const [profile , setProfile] = useState('white')
    const [SpaceName, setSpaceName] = useState('Dashboard')
    const [sidebarOpen, setSidebarOpen] = useState(false);

    useEffect(() => {
        if (location.pathname === '/Profile') {
            setProfile('aqua')
          setSpaceName('Merchant profile')
        } else {
            setProfile('white')
        }
        }, [location,setProfile,setSpaceName]);
       useEffect(()=> {
       try {
        const fectchUsers = async ()=> {
            const Users = await secondContractInstance.methods.Users(account).call()
            console.log(Users);
            console.log(Users[0])

           setMerchant ({

            name : Users[1],
            email    :  Users[7],
            phone    : Users[8],
            BusName  : Users[5],
            DesC     :Users[6],
            country : Users [9]
          }
)
          console.log("Array", Array)
         
            console.log(merchant)

          }
          fectchUsers()
       } catch (error) {
        
       }

       }, [secondContractInstance, account])
        const sidebarRef = useRef(null);

        useEffect(() => {
          const handleResize = () => {
            if (window.innerWidth <= 768) {
              setSidebarOpen(false);
            } else {
              setSidebarOpen(true);
            }
          };
      
          // Set initial state based on window size
          handleResize();
      
          // Add event listener for resize
          window.addEventListener('resize', handleResize);
      
          // Add event listener for clicks outside of sidebar
          const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
              setSidebarOpen(false);
            }
          };
      
          document.addEventListener('mousedown', handleClickOutside);
      
          // Clean up event listeners
          return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('mousedown', handleClickOutside);
          };
        }, []);
      
        const toggleSidebar = () => {
          setSidebarOpen(!sidebarOpen);
        };

  const [merchantInfos, setMerchantInfos] = useState({
  
    sales: 0,
    successfulSales: 0,
    positiveFeedback: 0,
    negativeFeedback: 0,
    totalPurchases: 0,
  });


  console.log(merchant)

  const [currentOpacity, setCurrentOpacity] = useState({});

  const handleFocus = (id) => {
    setCurrentOpacity((prevState) => ({
      ...prevState,
      [id]: '1'
    }));
  };

  const handleBlur = (id) => {
    setCurrentOpacity((prevState) => ({
      ...prevState,
      [id]: '0.3'
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('e target', e.target);
    setMerchant({...merchant , [name]  : value});
  };
  


  

  return (
    <>

    <div className='Profils'>
      <Sidebar ref={sidebarRef} open={sidebarOpen} navigate={navigate} profile={profile} />
      <Topbar toggleSidebar={toggleSidebar} navigate={navigate}   SpaceName={SpaceName}  />

      <div className="content">
        <div className="profile-settings">
          <div className="profile-container">
            <div className="profile-header">
              <Avatar name={merchant.name} />
              <div className="profile-current-info">
                <div className="statis">
                  <div className="sales">
                    <div className="stats-item">
                      <FontAwesomeIcon icon={faShoppingCart} /> Total Sales:
                      <p className="sales-name"> {merchantInfos.sales}</p>
                    </div>
                    <div className="stats-item">
                      <FontAwesomeIcon icon={faShoppingCart} /> Total Purchases:
                      <p className="sales-name"> {merchantInfos.totalPurchases}</p>
                    </div>
                    <div className="stats-item">
                      <FontAwesomeIcon icon={faCheckCircle} /> Successful sales:
                      <p className="sales-name"> {merchantInfos.successfulSales}</p>
                    </div>
                  </div>
                  <div className="feedback">
                    <div className="statsitem">
                      <FontAwesomeIcon icon={faThumbsUp} /> Positive Feedback:
                      <p className="sales-name"> {merchantInfos.positiveFeedback}</p>
                    </div>
                    <div className="statsitem">
                      <FontAwesomeIcon icon={faThumbsDown} /> Negative Feedback:
                      <p className="sales-name"> {merchantInfos.negativeFeedback}</p>
                    </div>
                    <div className="statsitem">
                      <FontAwesomeIcon icon={faXmarkCircle} /> Failed :
                      <p className="sales-name"> {merchantInfos.negativeFeedback}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="profile-info">
              <label htmlFor='name' style={{ opacity: currentOpacity['name'] || '0.3' }}>
                Name:
              </label>
              <input
                type="text"
                id='name'
                name="name"
                value={merchant.name}

                onChange={handleChange}

                onFocus={() => handleFocus('name')}
                onBlur={() => handleBlur('name')}
              />
              <label htmlFor='email' style={{ opacity: currentOpacity['email'] || '0.3' }}>
                Email:
              </label>
              <input
                type="email"
                id='email'
                name="email"
                value={merchant.email}
                onChange={handleChange}
                onFocus={() => handleFocus('email')}
                onBlur={() => handleBlur('email')}
              />
              <label htmlFor='phone' style={{ opacity: currentOpacity['phone'] || '0.3' }}>
                Phone:
              </label>
              <input
                type="text"
                id='phone'
                name="phone"
                value={merchant.phone}
                onChange={handleChange}
                onFocus={() => handleFocus('phone')}
                onBlur={() => handleBlur('phone')}
              />
              <label htmlFor='address' style={{ opacity: currentOpacity['address'] || '0.3' }}>
                Address:
              </label>
              <input
                type="text"
                id='address'
                name="address"
                value={merchant.country}
                onChange={handleChange}
                onFocus={() => handleFocus('address')}
                onBlur={() => handleBlur('address')}
              />
              <label htmlFor='businessName' style={{ opacity: currentOpacity['businessName'] || '0.3' }}>
                Business Name:
              </label>
              <input
                type="text"
                id='BusName'
                name="BusName"
                value={merchant.BusName}
                onChange={handleChange}
                onFocus={() => handleFocus('businessName')}
                onBlur={() => handleBlur('businessName')}
              />
              <label htmlFor='description' style={{ opacity: currentOpacity['description'] || '0.3' }}>
                Description:
              </label>
              <textarea
                id='DesC'
                className='texteraP'
                name="DesC"
                value={merchant.DesC}
                onChange={handleChange}
                onFocus={() => handleFocus('description')}
                onBlur={() => handleBlur('description')}
                style={{ padding: '20px', width: '100%' }}
              />
              <button className="submit-button">Submit</button>
            </div>
          </div>
        </div>
      </div>
      </div>
      <div style={{left:'80%' , filter:'blur(100px)'}} className='blurs'>

    </div>

    </>
  );
};

export default ProfileSettings;
