import React, { useEffect, useRef, useState, useContext } from 'react';
import Header from '../login/sideB';
import paraLogo from '../paralatest.svg';
import { Web3Context } from '../Web3Context';
import './index.css';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

const Register = ({ navigate }) => {
  const { account, secondContractInstance } = useContext(Web3Context);
  const [isChecked, setIsChecked] = useState(true);
  const [isUplineExist , setIsUplineExist] = useState(true);
  const [newTitle , setNewTitle ] = useState('REGISTER')
  const [isConnect , setIsconnect] = useState(false);
  const [isFailed , setIsFailed] = useState(false);
  const [isOK , setIsOK] = useState(false);

  


  const nameRef = useRef();
  const referrerIDRef = useRef();
  const businessRef = useRef();
  const emailRef = useRef();

 


  useEffect( () => {
   const checkstate= ()=> { 

    if (!account) {
      setIsconnect(false)
      console.log('current state is ' , !account )
     } else {
      setIsconnect(true)
     }

    }

    checkstate();

    createRain();
  }, [account , setIsconnect]);

  const checkNumnerOfUsers =  async () =>  {
    try {
      
   
    const CurrentID = referrerIDRef.current.value;
    console.log(CurrentID)
    const numberOfUser = await secondContractInstance.methods.numberOfUsers().call();
    
    setIsUplineExist(numberOfUser >= CurrentID);
    } catch (error) {
      
    }
  }
  const getFirstName = (firstName) => {
     return firstName.split(' ')[0];
  }



  const handleKeyDown = ()=> {
    const firstPart  = getFirstName(nameRef.current.value);
    setNewTitle(firstPart)
  }

  const getRegistration = async (e) => {

    e.preventDefault();
    
    
    const isRegistered = await secondContractInstance.methods.isRegistered(account).call();

    if (!account) {

      setIsconnect(false)
      
                     } else { 
    if (isRegistered) {
      navigate('/Dashboard')

                     } else { 

    try {
      const username = nameRef.current.value;
      const referrerID = referrerIDRef.current.value;
      const business = businessRef.current.value;
      const email = emailRef.current.value;
      const ReferrerAddress = await secondContractInstance.methods.idToAddress(referrerID).call();

      const transaction = await secondContractInstance.methods
        .registration(username, account, ReferrerAddress, business, email, '', '', '')
        .send({ from: account });

   if (transaction) {
                      setIsOK(true)
console.log('Redirecting....')

                      } else {
                        setIsFailed(true)

        }

    } catch (error) {
      console.error('Registration failed', error);


            }
         }
      }
  };

  const createRain = () => {
    const numCoins = 20; // Number of icons
    const container = document.querySelector('.register-all');
    for (let i = 0; i < numCoins; i++) {
      const coin = document.createElement('div');
      coin.classList.add('coin');
      coin.style.left = `${Math.random() * 100}vw`;
      coin.style.animationDuration = `${Math.random() * 6 + 10}s`;
      container.appendChild(coin);
    }
  };

  return (
    <>

    <div className="register-all">
      <div className="register-container">
        <Header navigate={navigate} />
        <div className="registerC" style={{ display: 'flex' }}>
          <div className="register-left">
            <div className="logo-container">
              <div className="logo"><img alt='paralogo'  src={paraLogo} /></div>
            </div>
          </div>
          <div className="register-right">
            <h2 >{newTitle} </h2>
            <p>It's completely free</p>
            <form onSubmit={getRegistration}>
              <div className="form-group">
                <label htmlFor="name">Username</label>
                <input type="text" id="name"  onKeyDown={handleKeyDown}  ref={nameRef} placeholder="Your full name" required />
              </div>
              <div className="form-group">
                <label htmlFor="ReferrerID">Referrer ID</label>
                <input type="number" id="ReferrerID" style={{border:`${isUplineExist ? 'none' : '1px solid red'}`}} ref={referrerIDRef} name="ReferrerID" onInput={checkNumnerOfUsers} placeholder="Referrer ID" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input type="email" id="email" ref={emailRef} placeholder="Email address" required />
              </div>
              <div className="form-group">
                <label htmlFor="Business">Business Name</label>
                <input type="text" id="Business" ref={businessRef} placeholder="EX: Best Exchange" />
              </div>
              <div className="form-group">
                <input type="checkbox" id="terms" name="terms" onChange={() => setIsChecked(!isChecked)} />
                <label htmlFor="terms">I agree to the terms and conditions</label>
              </div>
              <div className='btnR'>
                <button type="submit" disabled={isChecked} className="register-button">Create Account</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className='registration-blur' style={{ zIndex: '-1' }}></div>

    </div>
    <Footer/>
    <DisconnectNot isConnect={isConnect} />
    <Notification isConnect={isConnect} />
    <Failed isFailed={isFailed} />
    <RegistrationNoti navigate={navigate} isOK={isOK} />




    </>


  );
};

const Footer = () => {
  return (
    <footer style={{ textAlign: 'center' }} className="App-footer">
      <p>© 2024 PARADIZ. All rights reserved.</p>
    </footer>
  );
};

const  DisconnectNot = ({isConnect}) => {

    console.log(isConnect)

  return (
    <div  className="noti" style={{ display: `${!isConnect ? 'flex' : 'none'}` ,margin:'10px', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 , zIndex:900}}>

      <div className="noti-cont" style={{ background: 'transparent', padding: '30px', borderRadius: '30px' }}>

        <div className="noti-title" style={{ marginBottom: '10px', fontSize: '15px', fontWeight: 'bold', alignItems: 'center', textAlign: 'center'}}>

          Not Connected To Web3 <FontAwesomeIcon icon={faExclamationCircle} style={{ marginLeft: '10px', color: 'red' }} />
        </div>
        <div className="noti-content" style={{ fontSize: '10px' }}>
          <p style={{fontSize:'10px', color:'black'}}>You are not <span style={{color :'red', fontWeight:400}}> connected</span> <br/> you can read the public information of the contract.</p>
        </div>
      </div>
    </div>
  );
}


function Notification({isConnect}) { 
const [canDisplay, setCandisplay] = useState(false)

  useEffect(()=> {
     const setDisplay = () => {
      console.log('last fonction', isConnect )

      if (isConnect) {
        setCandisplay(true)
      } else {
        setCandisplay(false)
      }
     }
     setDisplay()

     setTimeout(() => {
          setCandisplay(false)
     }, 5000);

  } , [setCandisplay, isConnect])

  return (
    <div  className="noti" style={{ display: `${canDisplay ? 'flex' : 'none'}` ,margin:'10px', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 , zIndex:900}}>

      <div className="noti-cont" style={{ background: 'transparent', padding: '30px', borderRadius: '30px' }}>

        <div className="noti-title" style={{ marginBottom: '10px', fontSize: '15px', fontWeight: 'bold', alignItems: 'center', textAlign: 'center'}}>

          Connected To Web3 <FontAwesomeIcon icon={faCheckCircle} style={{ marginLeft: '10px', color: 'lime' }} />
        </div>
        <div className="noti-content" style={{ fontSize: '10px' }}>
          <p style={{fontSize:'10px', color:'black'}}>You are currently<span style={{color :'lime', fontWeight:400}}> connected</span> <br/> you can get started by with your Name</p>
        </div>
      </div>
    </div>
  );
}

const  Failed = ({isFailed}) => {
  const [canDisplay , setcanDisplay] = useState(false)

  console.log(isFailed)
   useEffect (() =>{
    if (!isFailed) {
      setcanDisplay (false) ;
    } else {
      setcanDisplay (true) ;
    }
   }, [isFailed,setcanDisplay,])

return (
  <div  className="noti" style={{ display: `${canDisplay ? 'flex' : 'none'}` ,margin:'10px', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 , zIndex:900}}>

    <div className="noti-cont" style={{ background: 'transparent', padding: '30px', borderRadius: '30px' }}>

      <div className="noti-title" style={{ marginBottom: '10px', fontSize: '15px', fontWeight: 'bold', alignItems: 'center', textAlign: 'center'}}>

       Registration Failed <FontAwesomeIcon icon={faExclamationCircle} style={{ marginLeft: '10px', color: 'red' }} />
      </div>
      <div className="noti-content" style={{ fontSize: '10px' }}>
        <p style={{fontSize:'10px', color:'black'}}>Registration <span style={{color :'red', fontWeight:400}}> Failed</span> <br/>  Please try again !</p>
      </div>
    </div>
  </div>
);
}

function RegistrationNoti({isOK, navigate}) { 

  const { account, contractInstance } = useContext(Web3Context);
  const [username, setUsername] = useState('')
  const [canDisplay, setCandisplay] = useState(false)
  
    useEffect( ()=> {
       const setDisplay =  async () => {
        if (isOK) {

        const User = await contractInstance.methods.name(account).call();
        console.log('last fonction', isOK )
  
          setCandisplay(true)
          setUsername(User)
        } else {
          setCandisplay(false)
        }
       }
       setDisplay()
  
       setTimeout(() => {
            if(isOK) {
              setCandisplay(false)

            navigate('/Dashboard')
          }
       }, 5000);
  
    } , [setCandisplay, isOK, navigate, account, contractInstance])
  
    return (
      <div  className="noti" style={{ display: `${canDisplay ? 'flex' : 'none'}` ,margin:'10px', justifyContent: 'center', alignItems: 'center', position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 , zIndex:900}}>
  
        <div className="noti-cont" style={{ background: 'transparent', padding: '30px', borderRadius: '30px' }}>
  
          <div className="noti-title" style={{ marginBottom: '10px', fontSize: '15px', fontWeight: 'bold', alignItems: 'center', textAlign: 'center'}}>
  
          Registration successfully <FontAwesomeIcon icon={faCheckCircle} style={{ marginLeft: '10px', color: 'lime' }} />
          </div>
          <div className="noti-content" style={{ fontSize: '10px' }}>
            <p style={{fontSize:'10px', color:'black'}}>Welcome Dear<span style={{color :'lime', fontWeight:400}}> {username}</span> <br/></p>
          </div>
        </div>
      </div>
    );
  }
  

export default Register;
