import React, { useRef, useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import currencies from './currencies/currencies';
import { Web3Context } from '../Web3Context';
import Methods from './payments/paymentsMethods';

const SellOrder = ({navigate}) => {
  const { account, contractInstance } = useContext(Web3Context);

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [customPaymentMethod, setCustomPaymentMethod] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [paymentPairs, setPaymentPairs] = useState([]);
  const [newPaymentInfo, setNewPaymentInfo] = useState('');
  const [amountToMax , setAmMax] = useState(0)
  const [amountToMin , setAmMin] = useState(0)
  const [max , setMax] = useState(0)
  const [userBalance , setUserBalance] = useState(0)

  const AmountRef = useRef();
  const MinRef = useRef();
  const MaxRef = useRef();
  const RateRef = useRef();

  useEffect(()=>  {
    const getB = async() => { 
      const userBalance = (Number(await contractInstance.methods.userBalance(account).call()))/1e18;
      if (userBalance) {
        setUserBalance(userBalance)
      }

    }
    getB()

    console.log(userBalance)
  },[userBalance])
  const handeCurrencyChanges= (selectOption)=>  {
    setSelectedCurrency(selectOption)
   }
   const handleSelectChange = (selectedOption) => {
    setSelectedPaymentMethod(selectedOption);
    setCustomPaymentMethod(''); // Effacer la méthode personnalisée lorsque une méthode prédéfinie est sélectionnée
  };

  const handleCustomMethodChange = (e) => {
    setCustomPaymentMethod(e.target.value);
    setSelectedPaymentMethod(null); // Effacer la méthode sélectionnée lorsque une méthode personnalisée est entrée
  };
  const publish = async (e) => {
    e.preventDefault();
    try {
      

      const AmountTo = (Number(AmountRef.current.value)) * 1e18;
      const Min = (Number(MinRef.current.value)) * 1e18;
      const Max = (Number(MaxRef.current.value)) * 1e18;
      const Rate = (Number(RateRef.current.value));
      const Devise = selectedCurrency.value;
      console.log(Devise)

      const response = await contractInstance.methods.placeSellOrder(AmountTo, Min, Max, Rate, Devise).send({ from: account });
      if (response) {
        console.log('Success');
        // Assuming you have a navigate function to redirect to the orders page
        navigate('/Orders');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#1e1e2d',
      borderColor: '#3d3d52',
      color: '#ffffff',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#ffffff',
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: '#1e1e2d',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#3d3d52' : '#1e1e2d',
      color: '#ffffff',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#ffffff',
    }),
  };

  const handleAddPaymentPair = () => {
    const method = customPaymentMethod || (selectedPaymentMethod && selectedPaymentMethod.label);
    if (method && newPaymentInfo) {
      setPaymentPairs([...paymentPairs, { method, info: newPaymentInfo }]);
      setCustomPaymentMethod('');
      setSelectedPaymentMethod(null);
      setNewPaymentInfo('');
    }
  };

  const handleRemovePaymentPair = (index) => {
    const updatedPairs = paymentPairs.filter((_, i) => i !== index);
    setPaymentPairs(updatedPairs);
  };

  return (
    <div className="order-form">
      <h2 className="nameSell" id='sellID' style={{ color: 'white' }}>Sell Order</h2>
      <form onSubmit={publish}>
        <label>
          Amount:
          <input  value={amountToMax  > userBalance ? userBalance : amountToMax}  style={{border: `${amountToMax > userBalance ? 'red 1px solid' : ''}`}}  onChange={(e)=> setAmMax(e.target.value)}  className="inp2" type="number" ref={AmountRef} />
        </label>
        <label>
          Min USDT:
          <input onChange={(e)=> setAmMin(e.target.value)} style={{border: `${amountToMin > userBalance ? 'red 1px solid' : ''}`}}  className="inp2" type="number" ref={MinRef} />
        </label>
        <label>
          Max USDT:
          <input  onChange={(e)=> setMax(e.target.value)} style={{border: `${max > userBalance ? 'red 1px solid' : ''}`}} className="inp2" type="number" ref={MaxRef} />
        </label>
        <label>
          Rate:
          <input className="inp2" type="number" ref={RateRef} />
        </label>
        <label>
          Devise:
          <Select
            onChange={handeCurrencyChanges}
            value={selectedCurrency}
            options={currencies}
            styles={customStyles}
            className="select-currency"
          />
        </label>
        <label>
          Select Payment Method:
          <Select
            options={Methods}
            onChange={handleSelectChange}
            value={selectedPaymentMethod}
            className="select-currency"
          />
        </label>
        <label>
          Or Enter Custom Payment Method:
          <input
            className="inp2"
            type="text"
            value={customPaymentMethod}
            onChange={handleCustomMethodChange}
          />
        </label>
        <label>
          New Payment Info:
          <input
            className="inp2"
            type="text"
            value={newPaymentInfo}
            onChange={(e) => setNewPaymentInfo(e.target.value)}
          />
        </label>
        <button type="button" style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }} className="AddP2" onClick={handleAddPaymentPair}>
          <FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '10px' }} />
          Add
        </button>
        <div>
          <h3>Payment Methods & Infos:</h3>
          <ul>
            {paymentPairs.map((pair, index) => (
              <li key={index} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '5px 0' }}>
                <span><strong>Method:</strong> {pair.method} - <strong>Info:</strong> {pair.info}</span>
                <p className="trash" style={{ padding: '10px', border: '1px solid white', width: '30px', height: '30px', display: 'flex', justifyContent: 'center' }} onClick={() => handleRemovePaymentPair(index)}>
                  <FontAwesomeIcon icon={faTrash} />
                </p>
              </li>
            ))}
          </ul>
        </div>
        <div className="btnO" style={{ marginTop: '20px' }}>
          <button className="SubSell" type="submit">Publish</button>
        </div>
      </form>
    </div>
  );
};


export default SellOrder;
